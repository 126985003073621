import React, { useCallback, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EventDetailSlider } from "../component/EventDetailSlider";
// import dataJson from "../component/cities.json";
// import dataTrips from "../component/trips.json";
// import itenary from "../component/itenary.json";
// import smallIMG from "../assets/small-img.jpg";
// import destination1 from "../assets/destination-img.jpg";
// import destination2 from "../assets/destination-img2.jpg";
// import destination3 from "../assets/destination-img3.jpg";
import Skeleton from "@mui/material/Skeleton";
import LazyLoad from "react-lazyload";
// import test from "../assets/test2.svg";
// import test2 from "../assets/test2.svg";
// import test3 from "../assets/test3.svg";
// import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
// import Fade from "@mui/material/Fade";
// import section1 from "../assets/Pune.png";
import {
  default as section2,
  default as section3,
  default as section4,
} from "../assets/Pune.png";
import section5 from "../assets/section5.jpeg";
// import section7 from "../assets/section7.jpeg";
// import section8 from "../assets/section8.jpeg";
import attraction1 from "../assets/attraction1.jpeg";
import attraction2 from "../assets/attraction2.jpeg";
import attraction4 from "../assets/attraction4.jpeg";
// import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
// import CardContent from "@mui/material/CardContent";
import { Icon as Iconfiy } from "@iconify/react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
// import Backdrop from "@mui/material/Backdrop";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import SwiperCore, {
  A11y,
  Autoplay,
  EffectFade,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ImageModal from "./ImageModal";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateUserInformation } from "../redux/actions";
// import test4 from "../assets/test4.svg";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Row,
} from "react-bootstrap";
import { createEnquiry, getTripById } from "../api";
import { TimelineComponent } from "./TimelineComponent";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import moment from "moment";
import SwiperComponent from "../component/SwiperComponent";

const footerSection = [
  { title: "Things to Carry" },
  { title: "Accommodation & Food" },
  { title: "Inclusion & Exclusion" },
  { title: "Terms & Conditions" },
  { title: "Cancellation Policy" },
  { title: "Rent/Purchase Gears" },
  { title: "Additional Details" },
];

// const sectionsLisExlpore = [
//   {
//     title: "Videos",
//     subTitle: "Exclusive footage from our camps",
//     trips: [
//       {
//         link: (
//           <iframe
//             width="300"
//             height="169"
//             src="https://www.youtube.com/embed/6CplA7Fxcj8"
//             title="YouTube video player"
//             frameborder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//             allowfullscreen
//           ></iframe>
//         ),
//         icon: section2,
//         url: "",
//       },
//       {
//         link: (
//           <iframe
//             width="300"
//             height="169"
//             src="https://www.youtube.com/embed/6CplA7Fxcj8"
//             title="YouTube video player"
//             frameborder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//             allowfullscreen
//           ></iframe>
//         ),
//         icon: section2,
//         url: "",
//       },
//       {
//         link: (
//           <iframe
//             width="300"
//             height="169"
//             src="https://www.youtube.com/embed/6CplA7Fxcj8"
//             title="YouTube video player"
//             frameborder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//             allowfullscreen
//           ></iframe>
//         ),
//         icon: section2,
//         url: "",
//       },
//       {
//         link: (
//           <iframe
//             width="300"
//             height="169"
//             src="https://www.youtube.com/embed/6CplA7Fxcj8"
//             title="YouTube video player"
//             frameborder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//             allowfullscreen
//           ></iframe>
//         ),
//         icon: section2,
//         url: "",
//       },
//       {
//         link: (
//           <iframe
//             width="300"
//             height="169"
//             src="https://www.youtube.com/embed/6CplA7Fxcj8"
//             title="YouTube video player"
//             frameborder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//             allowfullscreen
//           ></iframe>
//         ),
//         icon: section2,
//         url: "",
//       },
//       {
//         link: (
//           <iframe
//             width="300"
//             height="169"
//             src="https://www.youtube.com/embed/6CplA7Fxcj8"
//             title="YouTube video player"
//             frameborder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//             allowfullscreen
//           ></iframe>
//         ),
//         icon: section2,
//         url: "",
//       },
//       {
//         link: (
//           <iframe
//             width="300"
//             height="169"
//             src="https://www.youtube.com/embed/6CplA7Fxcj8"
//             title="YouTube video player"
//             frameborder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//             allowfullscreen
//           ></iframe>
//         ),
//         icon: section2,
//         url: "",
//       },
//     ],
//   },
// ];

SwiperCore.use([Navigation]);

export const EventDetails = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;
  const [pricesArray, setPricesArray] = useState([]);
  const [selectedDateNew, setSelectedDateNew] = useState("");
  const [typeOfModal, setTypeOfModal] = useState("");

  const [modalShow, setModalShow] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const openModal = (index) => {
    setCurrentSlide(index);
    setModalShow(true);
  };

  const closeModal = () => {
    setModalShow(false);
  };

  function hasCategoryWithShowOfflineBooking(categories) {
    // Use the Array.some() method to check if at least one category has showOfflineBooking set to true
    return categories.some(
      (category) => category.categoryId.showOfflineBooking === true
    );
  }
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInformation);

  function getPricesByDate(selectedDate) {
    // console.log("selectedCityData", selectedCityData);
    const matchingEntry = selectedCityData[0]?.dates_new?.find((entry) => {
      return (
        entry.date.toString() === selectedDate.toString() &&
        Number(entry.capacity) > 0
      );
    });
    if (matchingEntry) {
      return matchingEntry.prices;
    } else {
      return [];
    }
  }

  const handleUpdateUserInformation = (newDateFromSelection, actualDate) => {
    // console.log("actualDate", actualDate);
    let prices = getPricesByDate(actualDate);
    // console.log("prices", prices);
    if (prices) {
      setPricesArray(prices);
    }

    let subTotalAmount = price * 1;
    let gstAmount = (price * 1 * 5) / 100;
    let tcsAmount = 0;

    if (mainData?.tcs) {
      tcsAmount = ((price + gstAmount) * 1 * mainData?.tcs) / 100;
      // console.log("xyz2 tcsAmount", tcsAmount);
    }
    if (!tcsAmount) {
      tcsAmount = 0;
    }
    // console.log("xyz mainData?.tcs", mainData?.tcs);
    // console.log("xyz price", price);
    // console.log("xyz gstAmount", gstAmount);
    // console.log("xyz tcsAmount", tcsAmount);
    let total_fees = subTotalAmount + gstAmount + tcsAmount;
    // console.log("xyz total_fees", total_fees);

    const result = hasCategoryWithShowOfflineBooking(mainData?.categories);

    // console.log("newDateFromSelection", newDateFromSelection);
    const updatedInfo = {
      trip_name: title,
      trip_id: mainData?._id,
      trip_date: `${newDateFromSelection}-${selectedMonth}-2023`,
      trip_city: selectedCity,
      city_id: selectedCity,
      trip_amount: price,
      partialPayment: mainData?.partial_percentage,
      trip_tcs: mainData?.tcs,
      addOn: mainData?.addOn,
      subTotalAmount: subTotalAmount,
      gstAmount: gstAmount,
      tcsAmount: tcsAmount,
      total_fees: total_fees,
      showOfflineBooking: result,
      tripCategories: mainData?.categories,
    };

    dispatch(updateUserInformation({ ...userInfo, ...updatedInfo }))
      .then(() => {
        // const customer_id = localStorage.getItem("customer_id");
        // if (customer_id) {
        //   navigate("/customerPayNowScreen");
        // } else {
        //   navigate("/CustomerLogin");
        // }
      })
      .catch((error) => {
        alert("Failed to update user information.");
        // Handle error scenarios if needed
      });
  };
  const pTagRef = useRef();
  const goToCart = (priceNew) => {
    let subTotalAmount = priceNew * 1;
    let gstAmount = (priceNew * 1 * 5) / 100;
    let tcsAmount = 0;

    if (mainData?.tcs) {
      tcsAmount = ((priceNew + gstAmount) * 1 * mainData?.tcs) / 100;
      // console.log("xyz2 tcsAmount", tcsAmount);
    }
    if (!tcsAmount) {
      tcsAmount = 0;
    }
    // console.log("xyz mainData?.tcs", mainData?.tcs);
    // console.log("xyz priceNew", priceNew);
    // console.log("xyz gstAmount", gstAmount);
    // console.log("xyz tcsAmount", tcsAmount);
    let total_fees = subTotalAmount + gstAmount + tcsAmount;
    // console.log("xyz total_fees", total_fees);

    const result = hasCategoryWithShowOfflineBooking(mainData?.categories);

    const updatedInfo = {
      trip_name: title,
      trip_id: mainData?._id,
      trip_date: moment(selectedDateNew, "DD-MM-YYYY")
        .format("DD-MMM-YYYY")
        .toUpperCase(),
      trip_city: selectedCity,
      city_id: selectedCity,
      trip_amount: priceNew,
      partialPayment: mainData?.partial_percentage,
      trip_tcs: mainData?.tcs,
      addOn: mainData?.addOn,
      subTotalAmount: subTotalAmount,
      gstAmount: gstAmount,
      tcsAmount: tcsAmount,
      total_fees: total_fees,
      showOfflineBooking: result,
      tripCategories: mainData?.categories,
    };

    dispatch(updateUserInformation({ ...userInfo, ...updatedInfo }))
      .then(() => {
        const customer_id = localStorage.getItem("customer_id");
        if (customer_id) {
          navigate("/customerPayNowScreen");
        } else {
          navigate("/CustomerLogin");
        }
      })
      .catch((error) => {
        alert("Failed to update user information.");
        // Handle error scenarios if needed
      });
  };

  const [loading, setLoading] = useState(false);
  const [loadingApiData, setLoadingApiData] = useState(false);
  const [mainData, setMainData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [error, setError] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const navigate = useNavigate();
  const [largeShow, setlargeShow] = useState(false);
  const largemodalClose = () => setlargeShow(false);
  const largemodalShow = () => setlargeShow(true);

  const [contactUsModal, setcontactUsModal] = useState(false);
  const showContactUsModal = () => setcontactUsModal(true);
  const hideContactUsModal = () => setcontactUsModal(false);
  const closeModalFunction = () => {
    largemodalClose();
    setModalData([]);
    setModalTitle();
  };
  const setModalContent = (data) => {
    // console.log("setModalContent called", data);
    // { title: "Things to Carry" },
    // { title: "Accommodation & Food" },
    // { title: "Inclusion & Exclusion" },
    // { title: "Terms & Conditions" },
    // { title: "Cancellation Policy" },
    // { title: "Rent/Purchase Gears" },
    setModalTitle(data.title);

    if (data.title === "Things to Carry") {
      // console.log("setModalContent called", mainTripData?.things_to_carry);

      setModalData(mainTripData?.things_to_carry);
    } else if (data.title === "Accommodation & Food") {
      // console.log("setModalContent called", mainTripData?.things_to_carry);

      setModalData(mainTripData?.things_to_carry);
    } else if (data.title === "Inclusion & Exclusion") {
      // console.log("setModalContent called", mainTripData?.inclusion_exclusion);

      setModalData(mainTripData?.inclusion_exclusion);
    } else if (data.title === "Terms & Conditions") {
      // console.log("setModalContent called", mainTripData);

      setModalData(mainTripData?.terms_conditions);
    } else if (data.title === "Cancellation Policy") {
      // console.log("setModalContent called", mainTripData);

      setModalData(mainTripData?.cancellation_policy);
    } else if (data.title === "Rent/Purchase Gears") {
      // console.log("setModalContent called", mainTripData);

      setModalData(mainTripData?.rent_purchase_gears);
    } else if (data.title === "Additional Details") {
      // console.log("setModalContent called", mainTripData);

      setModalData(mainTripData?.how_to_reach);
    }

    largemodalShow();
  };
  const checkHavingData = (data) => {
    if (data.title === "Things to Carry") {
      if (mainData?.things_to_carry) {
        return true;
      }
    } else if (data.title === "Accommodation & Food") {
      // console.log("setModalContent called", mainData?.things_to_carry);

      if (mainData?.accommodation_food) {
        return true;
      }
    } else if (data.title === "Inclusion & Exclusion") {
      // console.log("setModalContent called", mainData?.inclusion_exclusion);

      if (mainData?.inclusion_exclusion) {
        return true;
      }
    } else if (data.title === "Terms & Conditions") {
      // console.log("setModalContent called", mainData);

      if (mainData?.terms_conditions) {
        return true;
      }
    } else if (data.title === "Cancellation Policy") {
      if (mainData?.cancellation_policy) {
        return true;
      }
    } else if (data.title === "Rent/Purchase Gears") {
      if (mainData?.rent_purchase_gears) {
        return true;
      }
    } else if (data.title === "Additional Details") {
      if (mainData?.how_to_reach) {
        return true;
      }
    }
    return false;
    // largemodalShow();
  };
  const [bookingShow, setBookingShow] = useState(false);
  const bookingModalClose = () => setBookingShow(false);
  const bookingModalOpen = () => setBookingShow(true);
  const [title, setTitle] = useState("");
  const [sub_title, setsub_title] = useState("");
  const [age, setage] = useState("");
  const [max_altitude, setmax_altitude] = useState("");
  const [about, setabout] = useState("");
  const [aboutSliced, setAboutSliced] = useState("");

  const [showAbout, setshowAbout] = useState(false);
  const [difficulty, setdifficulty] = useState("");
  const [price, setPrice] = useState();
  const [selectedCity, setSelectedcity] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedDate, setSelecteDate] = useState("");
  const [selectedDateFullFormat, setSelectedDateFullFormat] = useState("");
  const [duration, setDuration] = useState("3 Days");
  const [durationInHours, setDurationInHours] = useState(0);
  let { id } = useParams();
  const [cities, setCities] = useState([]);
  const [ite, setIte] = useState([]);
  const [months, setMonths] = useState([]);
  const [dates, setDates] = useState([]);
  const [mainTripData, setMainTripData] = useState([]);
  const [selectedCityData, setSelectedCityData] = useState([]);
  // const style = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: 400,
  //   bgcolor: "background.paper",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
  // };
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  // const whatsappIconClicked = () => {
  //   let number = 8447937907;
  //   let meesage = "";
  // };
  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://cdn.razorpay.com/widgets/affordability/affordability.js";
    script.async = true;

    document.body.appendChild(script);
  }, []);
  const updatePrice = (priceNew, title) => {
    setPrice(priceNew);
    goToCart(priceNew);
  };
  const updateCity = (city) => {
    // console.log("updateCity", city);
    const selectedCityData = cities?.filter((item) => item?.cityName === city);
    setSelectedCityData(selectedCityData);
    // console.log("selectedCityData", selectedCityData);
    // console.log("selectedCityDataname", selectedCityData[0]?.cityName);
    // console.log("selectedCityDataduration", selectedCityData[0]?.duration);
    // console.log("selectedCityDataprice", selectedCityData[0]?.prices[0].price);
    setSelectedcity(selectedCityData[0]?.cityName);
    setPrice(selectedCityData[0]?.prices[0]?.price);
    setDuration(selectedCityData[0]?.duration);
    setDurationInHours(selectedCityData[0]?.durationInHours);
    setMonths(selectedCityData[0]?.monthData);
    setDates(selectedCityData[0]?.monthData[0]?.dates);
    // console.log("setDates",selectedCityData[0]?.months[0]?.dates);
    setSelectedMonth(selectedCityData[0]?.monthData[0]?.month_name);
    // // setSelectedDate(Object.keys(selectedCityData[0]?.monthData[0].dates[0])[0]);
    // // setSelectedDate(Object.keys(selectedCityData[0]?.monthData[0].dates[0])[0]);
    // console.log("setSelectedDate",Object.keys(selectedCityData[0]?.monthData[0].dates[0])[0]);
    // setSelecteDate(selectedCityData[0]?.monthData[0].dates[0]?.date);
    selectedCityData[0]?.monthData[0]?.dates[0] &&
      setSelecteDate(
        Object.keys(selectedCityData[0]?.monthData[0]?.dates[0])[0]
      );
    selectedCityData[0]?.monthData[0]?.dates[0] &&
      setSelectedDateFullFormat(
        Object.values(selectedCityData[0]?.monthData[0]?.dates[0])[0]
      );

    setIte(selectedCityData[0]?.schedule);
  };
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  const compareKeys = (a, b) => {
    const keyA = Object.keys(a)[0];
    const keyB = Object.keys(b)[0];
    return keyA.localeCompare(keyB);
  };
  const updateMonth = (month) => {
    const selectedDates = months?.filter((item) => item?.month_name === month);
    // console.log("updateMonths", months, month);
    // console.log("selectedDates", selectedDates);
    // console.log("selectedDates123", selectedDates[0]?.dates.sort());
    setSelectedMonth(month);
    setDates(selectedDates[0]?.dates.sort(compareKeys));
  };
  const updatDate = (date, fullformat) => {
    // console.log("date123", date);
    setSelecteDate(date);
    setSelectedDateFullFormat(fullformat);
  };
  const getData = useCallback(async () => {
    setLoadingApiData(true);
    // console.log("getDatacalled");
    try {
      const data = await getTripById(id);
      if (data.data) {
        // console.log("maindata", data.data);
        setMainData(data.data);
        let tripsData = data.data;
        setIte(tripsData.cities[0]?.schedule);

        // console.log("tripsData", tripsData);
        // console.log("schedule", tripsData.cities[0]?.schedule);

        setMainTripData(tripsData);
        setTitle(tripsData?.title);
        setsub_title(tripsData?.sub_title);
        setage(tripsData?.age_group);
        setmax_altitude(tripsData?.max_altitude);
        setabout(tripsData?.about);
        setdifficulty(tripsData?.difficulty);
        setCities(tripsData?.cities);
        setSelectedCityData(tripsData?.cities);

        setSelectedcity(tripsData?.cities[0]?.cityName);
        // console.log("setSelectedcity", tripsData?.cities[0]?.cityName);
        //  setSelectedcity(selectedCityData[0]?.cities?.cityName);

        setPrice(tripsData?.cities[0]?.prices[0]?.price);
        setDuration(tripsData?.cities[0]?.duration);
        setDurationInHours(tripsData?.cities[0]?.durationInHours);

        setMonths(tripsData?.cities[0]?.monthData);
        setDates(tripsData?.cities[0]?.monthData[0]?.dates);
        // console.log("setDates", tripsData?.cities[0]?.monthData[0]?.dates);
        //  setSelectedDate(Object.keys(monthData[0].dates[0])[0]);

        setSelectedMonth(tripsData.cities[0]?.monthData[0]?.month_name);
        // console.log(
        //   "setSelectedMonth",
        //   tripsData[0].cities[0]?.monthData[0]?.month_name
        // );
        const datesObject = tripsData.cities[0]?.monthData[0]?.dates;
        if (datesObject && typeof datesObject === "object") {
          const datesKeys = Object.keys(datesObject);
          if (datesKeys.length > 0) {
            setSelecteDate(datesKeys[0]);
          }
        }
        // setSelecteDate(
        //   Object.keys(tripsData.cities[0]?.monthData[0]?.dates)[0]
        // );

        // console.log("schedule", tripsData.cities[0]?.schedule);
        //  setSelecteDate(tripsData.cities[0]?.monthData[0].dates);
        const pattern = /<div class="flex-1 overflow-hidden">([\s\S]*?)<\/p>/g;
        // console.log("about",about);
        setAboutSliced(
          tripsData?.about
            .replace(pattern, "")
            .toString()
            .trim()
            .slice(0, isMobile ? "180" : "350") + "..."
        );
      } else {
        setError("Something Went Wrong !! ");
      }
    } catch (err) {
      console.error("err12123", err);
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError(err.message);
      }
    }
    setLoadingApiData(false);
  }, [id, isMobile]);

  useEffect(() => {
    getData();
  }, [getData]);

  const toggleExpand = () => {
    setshowAbout(() => {
      return !showAbout;
    });
  };

  // const letterPattern = /[<]/;

  const selectPriceRef = useRef();
  const scrollToSelectPrice = () => {
    if (selectPriceRef.current) {
      selectPriceRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const Attractions = [
    {
      title: "Attractions",
      subTitle: "",
      trips: [
        { tripTitle: "Snow Trek", icon: attraction1, url: "" },
        { tripTitle: "Kasol Local Market", icon: attraction2, url: "" },
        { tripTitle: "Manikaran Temple", icon: attraction4, url: "" },
        { tripTitle: "Gurudwara", icon: attraction1, url: "" },
        { tripTitle: "Snow Trek", icon: attraction1, url: "" },
        { tripTitle: "Kasol Local Market", icon: attraction2, url: "" },
        { tripTitle: "Manikaran Temple", icon: attraction4, url: "" },
        { tripTitle: "Gurudwara", icon: attraction1, url: "" },
      ],
    },
  ];
  const sectionsList2 = [
    {
      title: "Join Us From",
      subTitle: "",
      trips: [
        { tripTitle: "Pune", icon: section2, url: "", price: "6000" },
        { tripTitle: "Mumbai", icon: section3, url: "", price: "6000" },
        { tripTitle: "Kolkata", icon: section4, url: "", price: "5000" },
        { tripTitle: "Delhi", icon: section5, url: "", price: "7000" },
      ],
    },
  ];

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const returnContactPersonFromTrip = () => {
    for (const item of mainData?.categories) {
      if (item.contactPerson) {
        return item.contactPerson;
      }
    }
    return null;
  };
  const formsSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const contactPersonId = returnContactPersonFromTrip();
    formData.append("contactPersonId", contactPersonId?._id);
    // console.log("formData", formData);
    const formJson = Object.fromEntries(formData.entries());
    addData(formJson);
    setLoading(false);
  };
  const addData = async (body) => {
    setError("");
    // setSuccessMessage("");

    setLoading(true);

    try {
      const apiData = await createEnquiry(body);
      if (apiData.error) {
        setError(apiData.error);
      } else {
        // getData();
        // handleClose3();
        // alert("Response Submitted");
        // toast.success(
        //   <p className="text-white tx-16 mb-0">
        //     Success : Enquiry Submitted !
        //   </p>,
        //   {
        //     position: toast.POSITION.TOP_RIGHT,
        //     hideProgressBar: false,
        //     autoClose: 3000,
        //     theme: "colored",
        //   }
        // );
      }
    } catch (err) {
      console.error("err", err.name);
      if (err.response) {
        setError(err.response.data.message);
        // setErrorToast(err.response.data.message);
      } else if (err.name === "AxiosError") {
        setError(err.message);
        // setErrorToast(err.message);
      } else {
        setError("something went wrong");
        // setErrorToast("something went wrong");
      }
    }

    hideContactUsModal();
    window.scrollTo({ top: 0, left: 0 });
    setLoading(false);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
    // console.log("useEffect 1 called");
  }, []);

  const [isSticky, setIsSticky] = useState(false);
  const [spacerElementState, setSpacerElementState] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      if (spacerElementState) {
        const spacerElement = document.querySelector(".spacer");
        const booknowWhatsappElement =
          document.querySelector(".booknowWhatsapp");
        const scrollY = window.scrollY;
        const spacerOffset =
          spacerElement.getBoundingClientRect().top + scrollY;
        const booknowWhatsappOffset =
          booknowWhatsappElement.getBoundingClientRect().height;
        const windowHeight = window.innerHeight + scrollY;
        // console.log("spacerOffset ", spacerOffset + 90);
        // console.log("windowHeight ", windowHeight);

        if (windowHeight >= spacerOffset + booknowWhatsappOffset) {
          setIsSticky(false);
        } else {
          setIsSticky(true);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [spacerElementState]);

  const handleSpacerLoad = (element) => {
    setSpacerElementState(element);
  };

  // const [selectNam, setSelectedcity] = useState("Pune");

  // const [eventname, setEventname] = useState(
  //   "Hampi Gokarna Murudeshwar Honnavar Backpacking"
  // );
  // const [overview, setOverview] = useState(
  //   "Want to explore your Heritage and have those beach vibes as well? Here is our plan curated just for you"
  // );
  // const [description, setDiscription] = useState(
  //   "Want to explore your Heritage and have those beach vibes as well? Here is our plan curated just for you"
  // );

  // const pointsList = [
  //   {
  //     name: "Participants",
  //     vale: "1,40,000+",
  //     icon: test,
  //   },
  //   {
  //     name: "Volunteers",
  //     vale: "1600",
  //     icon: test2,
  //   },
  //   {
  //     name: "Treks",
  //     vale: "45+",
  //     icon: test3,
  //   },
  //   {
  //     name: "Years",
  //     vale: "8+",
  //     icon: test4,
  //   },
  // ];

  const swiperRef = useRef(null);

  // Function to initialize Swiper
  const initSwiper = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      swiper.init();
      swiper.navigation.init();
      swiper.update();
    }
  };

  useEffect(() => {
    initSwiper(); // Initialize Swiper when the component mounts
  }, []);

  // const handleNext = () => {
  //   if (swiperRef.current) {
  //     const swiper = swiperRef.current.swiper;
  //     swiper.slideNext();
  //   }
  // };
  // const handlePrev = () => {
  //   if (swiperRef.current) {
  //     const swiper = swiperRef.current.swiper;
  //     swiper.slidePrev();
  //   }
  // };

  // useEffect(() => {
  //   setCities(dataJson.cities);
  //   setIte(itenary.pune);

  // }, [cities]);
  return (
    <div style={{ overflow: "auto" }}>
      {loadingApiData ? (
        <Skeleton
          variant="rectangular"
          // sx={{ marginTop: "5px" }}
          width={"100%"}
          height={400}
        />
      ) : (
        <>
          <ImageModal
            show={modalShow}
            onHide={closeModal}
            typeOfModal={typeOfModal}
            images={mainData?.images?.filter(
              (data) => data?.type === "Detail Screen"
            )}
            attractions={mainData?.attraction}
            initialSlide={currentSlide}
          />
          <EventDetailSlider images={mainData?.images} title={title} />
          <ToastContainer />
          <section className="eventDetailsSections">
            <div className="container"></div>
            <div className="row mx-0">
              <div className="col-lg-7">
                {" "}
                <h1 className="eventDetailsHeading">{title}</h1>
                <h2 className="eventDetailsSubHeading">{sub_title}</h2>
                <div className="container-fluid">
                  <div style={{}} className="row mt-3  text-left  mb-4">
                    <div className="col-lg-3 col-6 col-sm-6 mt-2">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <div>
                          {" "}
                          <Iconfiy
                            width="32"
                            height="32"
                            color="#36b54a"
                            icon="uil:calender"
                          />{" "}
                        </div>
                        <div
                          style={{
                            // marginLeft:"-40px",
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "5px",
                          }}
                        >
                          <div className="">
                            {" "}
                            <span className="bookIconTitle">Duration</span>{" "}
                          </div>
                          <div>
                            <span className="bookIconSubTitle">
                              {" "}
                              {duration}
                            </span>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-6 col-sm-6 mt-2">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <div>
                          {" "}
                          <Iconfiy
                            width="32"
                            height="32"
                            color="#36b54a"
                            icon="uil:map"
                          />{" "}
                        </div>
                        <div
                          style={{
                            // marginLeft:"-40px",
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "5px",
                          }}
                        >
                          <div className="">
                            {" "}
                            <span className="bookIconTitle">Difficulty</span>
                          </div>
                          <div>
                            <span className="bookIconSubTitle">
                              {" "}
                              {difficulty}
                            </span>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-6 col-sm-6 mt-2">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <div>
                          {" "}
                          <Iconfiy
                            width="32"
                            height="32"
                            color="#36b54a"
                            icon="uil:users-alt"
                          />{" "}
                        </div>
                        <div
                          style={{
                            // marginLeft:"-40px",
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "5px",
                          }}
                        >
                          <div className="">
                            {" "}
                            <span className="bookIconTitle">Age</span>
                          </div>
                          <div>
                            <span className="bookIconSubTitle"> {age}</span>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3  col-6 text-left col-sm-6 mt-2">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <div>
                          {" "}
                          <Iconfiy
                            width="32"
                            height="32"
                            color="#36b54a"
                            icon="uil:mountains-sun"
                          />{" "}
                        </div>
                        <div
                          style={{
                            // marginLeft:"-40px",
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "5px",
                          }}
                        >
                          <div className="">
                            {" "}
                            <span className="bookIconTitle">
                              Max Altutude
                            </span>{" "}
                          </div>
                          <div>
                            <span className="bookIconSubTitle">
                              {max_altitude}
                            </span>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider />
                <div
                  className=""
                  style={{ marginTop: "20px", paddingRight: "12px" }}
                >
                  <h4 className="bookingAbout">About</h4>
                  <div
                    style={{ marginTop: "9px" }}
                    className="bookingDescription"
                  >
                    <p
                      className={
                        showAbout ? "textOverFlow_ellipsis timelineULOL" : ""
                      }
                      ref={pTagRef}
                      dangerouslySetInnerHTML={{
                        __html: about && (showAbout ? about : aboutSliced),
                      }}
                    ></p>
                    {/* <p>{letterPattern.test(aboutSliced) ? "true" : "false"}</p> */}
                  </div>
                  <div>
                    <p className="anchor_tag" onClick={toggleExpand}>
                      Read {showAbout ? "Less" : "More"}
                    </p>
                  </div>
                  {mainData && mainData?.brochure && (
                    <a
                      download={true}
                      href={mainData?.brochure}
                      className="bookDownloadBrowser"
                    >
                      <Iconfiy
                        color="#36b54a"
                        width={16}
                        height={16}
                        fontWeight={600}
                        icon="material-symbols:download"
                      />

                      <span className="bookDownloadBrowserText">Brochure</span>
                    </a>
                  )}
                </div>
              </div>
              <div className="col-lg-5">
                <div className="bookNowBox">
                  <span className="bookingprice">₹ {price}</span>
                  <span className="bookingpricePerPerson"> / person</span>

                  <br />

                  <span className="bookingicludes">Includes</span>
                  {/* <div style={{}}>
          <Stack
            // direction={{ xs: "column", sm: "row" }}
            // spacing={{ xs: 1, sm: 2, md: 4 , lg:2 }}
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            flexWrap="wrap"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                alignContent: "center",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "16px",
                  backgroundColor: "#E3E3E3",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <Iconfiy
                  width="16"
                  height="16"
                  color="#36b54a"
                  icon="uil:calender"
                />{" "}
              </div>

              <div>
                {" "}
                <span className="includeTextTitle">GST</span>{" "}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                alignContent: "center",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "16px",
                  backgroundColor: "#E3E3E3",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <Iconfiy
                  width="16"
                  height="16"
                  color="#36b54a"
                  icon="uil:calender"
                />{" "}
              </div>

              <div>
                {" "}
                <span className="includeTextTitle">GST</span>{" "}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                alignContent: "center",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "16px",
                  backgroundColor: "#E3E3E3",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <Iconfiy
                  width="16"
                  height="16"
                  color="#36b54a"
                  icon="uil:calender"
                />{" "}
              </div>

              <div>
                {" "}
                <span className="includeTextTitle">GST</span>{" "}
              </div>
            </div>
          </Stack>
        </div> */}

                  <div className="row">
                    {/* <div className="col-lg-6">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          alignContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "16px",
                            backgroundColor: "#E3E3E3",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <Iconfiy
                            width="16"
                            height="16"
                            color="#36b54a"
                            icon="uil:bill"
                          />{" "}
                        </div>

                        <div>
                          {" "}
                          <span className="includeTextTitle">GST</span>{" "}
                        </div>
                      </div>
                    </div> */}
                    <div className="col-lg-6">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          alignContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "16px",
                            backgroundColor: "#E3E3E3",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <Iconfiy
                            width="16"
                            height="16"
                            color="#36b54a"
                            icon="uil:utensils-alt"
                          />{" "}
                        </div>

                        <div>
                          {" "}
                          <span className="includeTextTitle">Food</span>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          alignContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "16px",
                            backgroundColor: "#E3E3E3",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <Iconfiy
                            width="16"
                            height="16"
                            color="#36b54a"
                            icon="uil:mountains-sun"
                          />
                        </div>

                        <div>
                          {" "}
                          <span className="includeTextTitle">
                            Accommodation
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          alignContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "16px",
                            backgroundColor: "#E3E3E3",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <Iconfiy
                            width="16"
                            height="16"
                            color="#36b54a"
                            icon="uil-user-circle"
                          />{" "}
                        </div>

                        <div>
                          {" "}
                          <span className="includeTextTitle">
                            Instructor
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          alignContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "16px",
                            backgroundColor: "#E3E3E3",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <Iconfiy
                            width="16"
                            height="16"
                            color="#36b54a"
                            icon="uil-band-aid"
                          />{" "}
                        </div>

                        <div>
                          {" "}
                          <span className="includeTextTitle">
                            First Aid
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          alignContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "16px",
                            backgroundColor: "#E3E3E3",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <Iconfiy
                            width="16"
                            height="16"
                            color="#36b54a"
                            icon="uil-bus-alt"
                          />{" "}
                        </div>

                        <div>
                          {" "}
                          <span className="includeTextTitle">
                            Travelling
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />
                  <button
                    className="bookingNowButton"
                    onClick={() => {
                      mainData?.tripLabel === "customised"
                        ? showContactUsModal()
                        : bookingModalOpen();
                    }}
                  >
                    <span style={{ color: "#FFFFFF" }}> Book Now</span>
                  </button>
                </div>
              </div>
            </div>
          </section>
          {sectionsList2 &&
            sectionsList2.map((sectionListData, i) => {
              return (
                <div className="bookEventDetailsCity" key={i}>
                  <span className="mainSectionsTitle">
                    {sectionListData.title}
                  </span>
                  <Swiper
                    style={{ paddingBottom: "20px" }}
                    modules={[
                      Navigation,
                      Pagination,
                      A11y,
                      EffectFade,
                      Autoplay,
                      Scrollbar,
                      Mousewheel,
                    ]}
                    scrollbar={{ draggable: true }}
                    breakpoints={{
                      320: {
                        slidesPerView: 1.7,
                        spaceBetween: 30,
                      },
                      480: {
                        slidesPerView: 2.3,
                        spaceBetween: 50,
                      },
                      640: {
                        slidesPerView: 2.5,
                        spaceBetween: 40,
                      },
                      768: {
                        slidesPerView: 2.8,
                        spaceBetween: 35,
                      },
                      1024: {
                        slidesPerView: 4,
                        spaceBetween: 35,
                      },
                      1440: {
                        slidesPerView: 4.5,
                        spaceBetween: 40,
                      },
                    }}
                    className="swiperMain mainScrollHeight"
                  >
                    {cities &&
                      cities.map((sectionListDataTrips, i) => {
                        return (
                          <SwiperSlide key={i}>
                            <Card
                              onClick={() => {
                                updateCity(sectionListDataTrips?.cityName);
                              }}
                              className={`${
                                selectedCity === sectionListDataTrips?.cityName
                                  ? " booknowCityBorder"
                                  : ""
                              } booknowCityCard`}
                            >
                              <LazyLoad height={120} offset={50}>
                                <CardMedia
                                  image={sectionListDataTrips?.cityID?.icon}
                                  sx={{
                                    height: "120px",
                                    width: "168px",
                                    borderRadius: "15px",
                                  }}
                                  title={sectionListDataTrips?.cityName}
                                />
                              </LazyLoad>
                              <span
                                style={{ marginTop: "10px" }}
                                className="booknowCityText"
                              >
                                {sectionListDataTrips?.cityName}
                              </span>
                              <div>
                                <span
                                  className="booknowCityTextDate"
                                  style={{ float: "left" }}
                                >
                                  ₹ {sectionListDataTrips?.prices[0]?.price}/-
                                </span>
                                <span
                                  className="booknowCityTextDate"
                                  style={{ float: "right" }}
                                >
                                  <Iconfiy
                                    style={{ marginRight: "5px" }}
                                    icon="uil:calender"
                                  />
                                  <span className="booknowCityTextDate">
                                    {sectionListDataTrips?.duration}
                                  </span>
                                </span>
                              </div>
                            </Card>
                            {/* <Card
                      className="bookCityCard"
                    >
                      <CardMedia
                        sx={{
                          height: "120px",
                          width: "168px",
                          borderRadius: "10px",
                        }}
                        image={sectionListDataTrips.icon}
                        title="green iguana"
                      />
                      <span className="subSectionTitle">
                        {sectionListDataTrips.tripTitle}
                      </span>
                    </Card> */}
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                </div>
              );
            })}

          <div
            className=""
            style={{
              width: "100%",
              // height: "174.19px",
              background: "#F8F8F8",
              boxSizing: "border-box",
            }}
          >
            <div
              className="phoneNumberDivLogin2"
              style={{
                paddingTop: "20px",
                paddingBottom: "20px",
                cursor: "pointer",
              }}
            >
              <p className="booknowDateText">Dates from {title}</p>
              {/* <Stack
                // style={{ overflow: "scroll", paddingBottom: "20px" }}
                spacing={2}
                direction="row"
                sx={{ marginTop: "10px" }}
              > */}
              <Row style={{ marginTop: "10px" }}>
                {months &&
                  months.map((data, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          boxSizing: "border-box",
                          // width: "10%",
                          paddingBottom: "10px",
                          paddingTop: "10px",
                          marginLeft: "5px",
                          marginRight: "5px",
                        }}
                      >
                        <Button
                          onClick={() => {
                            if (selectedMonth !== data?.month_name) {
                              updateMonth(data?.month_name);
                            }
                          }}
                          className={`button_onHover ${
                            selectedMonth === data?.month_name
                              ? "background_onHover"
                              : ""
                          }`}
                          key={index}
                          // onClick={() => { console.log('onClick'); }}
                          sx={{
                            background: `${
                              selectedMonth === data?.month_name
                                ? "#36b54a"
                                : ""
                            }`,
                            border: "1px solid #36b54a",
                            borderRadius: "4px",
                            color: `${
                              selectedMonth === data?.month_name
                                ? "#FFFFFF"
                                : "#36b54a"
                            }`,
                            fontWeight: "500",
                            fontFamily: "Montserrat",
                          }}
                          tabIndex={0} // Add this property
                        >
                          {data?.month_name}
                        </Button>
                      </div>
                    );
                  })}
              </Row>
              {/* </Stack> */}
              {/* <Stack
      style={{ overflow: "scroll", paddingBottom: "20px" }}
      spacing={2}
      direction="row"
      sx={{ marginTop: "10px" }}
    > */}
              <Row style={{ marginTop: "10px" }}>
                {dates &&
                  dates.map((data, i) => {
                    // console.log("dates map", dates);
                    const key = Object.keys(data)[0];
                    const value = Object.values(data)[0];
                    return (
                      <button
                        className={`buttonDate ${
                          selectedDate === key ? "selected" : ""
                        }`}
                        key={i}
                        onClick={() => {
                          if (selectedDate !== key) {
                            updatDate(key, value);
                          }
                        }}
                      >
                        {key}
                        {/* <span className="bookNowSingleDate"></span> */}
                      </button>
                    );
                  })}
                {/* <button
      style={{
        width: "36px",
        height: "36px",
        borderRadius: "50%",
        border: " 2px solid #36b54a",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "12px",
      }}
    >
      <span className="bookNowSingleDate">19</span>
    </button>
    <button
      style={{
        width: "36px",
        height: "36px",
        borderRadius: "50%",
        border: "2px solid #E3E3E3",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "12px",
      }}
    >
      <span className="bookNowSingleDate">30</span>
    </button> */}
                {/* </Stack> */}
              </Row>
            </div>
          </div>

          <TimelineComponent
            selectedDateFullFormat={selectedDateFullFormat}
            iternityDetails={ite}
          />
          {/* {Attractions &&
  Attractions.map((sectionListData, i) => {
    return ( */}
          {mainData?.attraction?.length > 0 ? (
            <SwiperComponent
              title="Attractions"
              classTitle="mainSectionsAttractions"
              breakpoints={{
                320: {
                  slidesPerView: 1.7,
                  spaceBetween: 30,
                },
                480: {
                  slidesPerView: 2.3,
                  spaceBetween: 50,
                },
                640: {
                  slidesPerView: 2.5,
                  spaceBetween: 40,
                },
                768: {
                  slidesPerView: 2.8,
                  spaceBetween: 35,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 35,
                },
                1440: {
                  slidesPerView: 4.5,
                  spaceBetween: 40,
                },
              }}
            >
              {mainData &&
                mainData?.attraction?.map((sectionListDataTrips, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <LazyLoad height={150} offset={50}>
                        <CardMedia
                          image={sectionListDataTrips?.link}
                          className="attractionsSectionListDataTrips"
                          title={sectionListDataTrips.title}
                          onClick={() => {
                            setTypeOfModal("attractions");
                            openModal(i);
                          }}
                        />
                      </LazyLoad>
                      <h5 className="subSectionTitle">
                        {sectionListDataTrips.title}
                      </h5>
                    </SwiperSlide>
                  );
                })}
            </SwiperComponent>
          ) : (
            ""
          )}
          {/* );
  })} */}

          {/* Swiper using css */}
          {/* <div style={{paddingLeft:"90px", color:"green", fontWeight:"bold", fontSize:"20px", marginTop:"30px"}}>Similar Treks</div>
    <div style={{overflowX:"auto", overflowY:"hidden", whiteSpace:"nowrap", paddingLeft:"6%", paddingTop:"50px", paddingBottom:"50px"}}>
          {mainData?.categories?.length > 0 &&
              mainData?.categories[0]?.trips?.map((sectionListDataTrips, i) => {
                if (sectionListDataTrips?._id === mainData?._id) {
                  return null;
                }
              return (
                <div
                  style={{
                    display: "inline-block",
                    width: "250px",
                    position: "relative",
                    marginRight: "20px",
                  }}
                >
                  <img
                    style={{
                      height: "150px",
                      borderRadius: "5%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    src={sectionListDataTrips?.images[0]?.link}
                    alt=""
                  />
                  <span className="subSectionTitle text-wrap">
                    {sectionListDataTrips.title}
                  </span>
                </div>
              );
            })}
            </div> */}

          {/* {sectionsLisExlpore &&
  sectionsLisExlpore.map((sectionListData, i) => {
    return ( */}

          {/* );
  })} */}
          <div className="spacer" ref={handleSpacerLoad}></div>
          <div className={true ? "sticky-container" : ""}>
            <div className="booknowWhatsapp">
              <span className="booknowWhatsappText">
                From ₹{price} / person
              </span>

              <div style={{ float: "right", marginBottom: "10px" }}>
                <Button
                  className="button_onHover"
                  sx={{
                    background: "#36b54a",
                    border: "1px solid #36b54a",
                    color: "#FFFFFF",
                    fontWeight: "600",
                    // marginBottom:"5px",
                    height: "38px",
                    borderRadius: "13px",
                    fontFamily: "Montserrat",
                    // marginBottom:"10px"
                    // marginBottom:"-20px"
                  }}
                  onClick={() => {
                    mainData?.tripLabel === "customised"
                      ? showContactUsModal()
                      : bookingModalOpen();
                  }}
                >
                  Book Now
                </Button>
                <button
                  style={{
                    background: "#FFFFFF",
                    border: "1px solid #36b54a",
                    color: "#FFFFFF",
                    fontWeight: "600",
                    // marginBottom:"5px",
                    height: "38px",
                    borderRadius: "13px",
                    marginLeft: "10px",
                    width: "34px",
                    boxShadow: "0 0 0 .2rem rgba(54,181,74,.5)",
                    // marginBottom:"-20px"
                    // marginBottom:"10px"
                  }}
                  onClick={() => {
                    showContactUsModal();
                  }}
                >
                  <Iconfiy
                    width="16"
                    height="20"
                    color="#36b54a"
                    icon="material-symbols:chat-outline"
                  />{" "}
                </button>
              </div>
            </div>
          </div>

          <SwiperComponent
            title="Images"
            classTitle="mainSectionsAttractions"
            breakpoints={{
              320: {
                slidesPerView: 1.3,
                spaceBetween: 30,
              },
              480: {
                slidesPerView: 1.5,
                spaceBetween: 50,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              768: {
                slidesPerView: 2.3,
                spaceBetween: 35,
              },
              1024: {
                slidesPerView: 3.2,
                spaceBetween: 35,
              },
              1440: {
                slidesPerView: 4.2,
                spaceBetween: 40,
              },
            }}
          >
            {mainData?.images?.length > 0 &&
              mainData?.images?.map((data, i) => {
                if (data?.type === "Detail Screen") {
                  return (
                    <SwiperSlide key={i}>
                      <img
                        style={{
                          width: "100%",
                          height: "150px",
                          objectFit: "cover",
                          borderRadius: "10px",
                          boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
                          cursor: "pointer",
                        }}
                        src={data.link}
                        key={i}
                        alt={`${i} slide`}
                        onClick={() => {
                          setTypeOfModal("images");
                          openModal(i - 1);
                        }}
                      />
                    </SwiperSlide>
                  );
                } else {
                  return null;
                }
              })}
          </SwiperComponent>
          {mainData?.videos?.length > 0 ? (
            // <div className="mainSectionsExplore">
            //   <span className="mainSectionsTitle">Videos</span>
            //   <span className="mainSectionsSubTitle">
            //     Exclusive footage from our camps
            //   </span>
            //   <span className="iconContainerLeft">
            //     <Iconfiy
            //       width="32"
            //       height="32"
            //       color="#36b54a"
            //       icon="mingcute:arrow-left-fill"
            //       onClick={handlePrev}
            //     />
            //   </span>
            //   <span className="iconContainerRight">
            //     <Iconfiy
            //       width="32"
            //       height="32"
            //       color="#36b54a"
            //       icon="mingcute:arrow-right-fill"
            //       onClick={handleNext}
            //     />
            //   </span>
            //   <Swiper
            //     modules={[
            //       Navigation,
            //       Pagination,
            //       // Scrollbar,
            //       A11y,
            //       EffectFade,
            //       Autoplay,
            //     ]}
            //     breakpoints={{
            //       320: {
            //         slidesPerView: 1,
            //         spaceBetween: 20,
            //       },
            //       480: {
            //         slidesPerView: 2.3,
            //         spaceBetween: 50,
            //       },
            //       640: {
            //         slidesPerView: 3.3,
            //         spaceBetween: 40,
            //       },
            //       720: {
            //         slidesPerView: 3.5,
            //         spaceBetween: 40,
            //       },
            //     }}
            //     className="swiperMain"
            //   >
            //     {mainData?.videos &&
            //       mainData?.videos?.map((data, i) => {
            //         return (
            //           <SwiperSlide key={i}>
            //             <Card
            //               className="mainSectionCardExlpore"
            //               sx={{ borderRadius: "10px" }}
            //             >
            //               <div
            //                 dangerouslySetInnerHTML={{ __html: data?.link }}
            //               ></div>
            //             </Card>
            //           </SwiperSlide>
            //         );
            //       })}
            //   </Swiper>
            // </div>

            <SwiperComponent
              title="Videos"
              classTitle="mainSectionsExplore"
              subTitle=" Exclusive footage from our camps"
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 50,
                },
                480: {
                  slidesPerView: 2,
                  spaceBetween: 100,
                },
                640: {
                  slidesPerView: 2.5,
                  spaceBetween: 150,
                },
                768: {
                  slidesPerView: 2.8,
                  spaceBetween: 200,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 250,
                },
                1440: {
                  slidesPerView: 4.5,
                  spaceBetween: 300,
                },
              }}
            >
              {mainData?.videos &&
                mainData?.videos?.map((data, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <Card
                        className="mainSectionCardExlpore"
                        sx={{ borderRadius: "10px" }}
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html: data?.link }}
                        ></div>
                      </Card>
                    </SwiperSlide>
                  );
                })}
            </SwiperComponent>
          ) : (
            ""
          )}
          {mainTripData && mainTripData.faq && (
            <section className="eventDetailsSections mt-6">
              <span className="mainSectionsTitle faqSection">FAQ</span>

              {mainTripData &&
                mainTripData.faq?.map((data, i) => {
                  return (
                    <Accordion className="accordianFaq timelineULOL" key={i}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${i}abc`}
                        id={`${i}abc`}
                      >
                        <Typography>{data.title}</Typography>
                      </AccordionSummary>

                      <AccordionDetails
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      ></AccordionDetails>
                    </Accordion>
                  );
                })}
            </section>
          )}

          {Attractions &&
            Attractions.map((sectionListData, i) => {
              return (
                <div className="mainSectionsAttractionsFooter" key={i}>
                  <div className="row" style={{ marginBottom: "20px" }}>
                    {footerSection &&
                      footerSection.map((data, i) => {
                        // console.log("attraction data: ", data);
                        const isHavingData = checkHavingData(data);
                        if (isHavingData === false) {
                          return null;
                        }
                        // let isaHavingData = mainData?
                        return (
                          <div
                            key={i}
                            className="col-lg-4 mb-4"
                            onClick={() => {
                              setModalContent(data);
                            }}
                          >
                            <h6
                              className="bookingFooterModalsText"
                              style={{ float: "left", marginBottom: "opx" }}
                            >
                              {data.title}
                            </h6>
                            <div style={{ float: "right" }}>
                              <Iconfiy
                                width="24"
                                height="28"
                                color="#1C1C1C"
                                icon="uil:arrow-right"
                              />{" "}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  {/* <Modal size="lg" show={largeShow} onHide={largemodalClose}>

        </Modal> */}
                  <Modal size="lg" show={largeShow} onHide={largemodalClose}>
                    <ModalHeader>
                      <ModalTitle>{modalTitle}</ModalTitle>
                      <span
                        style={{ cursor: "pointer" }}
                        className="d-flex ms-auto"
                        onClick={largemodalClose}
                      >
                        {/* <i className="fe fe-x ms-auto"></i> */}X
                      </span>
                    </ModalHeader>
                    <ModalBody>
                      <div
                        className="container font_style timelineULOL"
                        dangerouslySetInnerHTML={{ __html: modalData }}
                      ></div>
                    </ModalBody>
                    <ModalFooter>
                      <Button variant="secondary" onClick={closeModalFunction}>
                        Close
                      </Button>
                    </ModalFooter>
                  </Modal>

                  <Modal
                    size="lg"
                    show={contactUsModal}
                    onHide={hideContactUsModal}
                  >
                    <ModalHeader>
                      <ModalTitle>Enquiry Form</ModalTitle>
                      <span
                        style={{ cursor: "pointer" }}
                        className="d-flex ms-auto"
                        onClick={hideContactUsModal}
                      >
                        <i className="fe fe-x ms-auto"></i>
                      </span>
                    </ModalHeader>
                    <form
                      method="post"
                      onSubmit={formsSubmit}
                      // autocomplete="off"
                      encType="multipart/form-data"
                    >
                      <ModalBody style={{ fontFamily: "Montserrat" }}>
                        <div className="form-group">
                          <label htmlFor="">
                            Name <span className="text-danger">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            required
                            name="name"
                            placeholder="Enter First Name And Last Name"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="">
                            Mobile <span className="text-danger">*</span>{" "}
                          </label>
                          <input
                            type="number"
                            required
                            name="mobile"
                            placeholder="Enter Mobile Number"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="">
                            Email <span className="text-danger">*</span>{" "}
                          </label>
                          <input
                            type="email"
                            required
                            name="email"
                            placeholder="Enter Email"
                            className="form-control"
                          />
                          <input
                            type="hidden"
                            required
                            name="trip"
                            value={title || ""}
                            placeholder="Enter Email"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="">
                            Message <span className="text-danger">*</span>{" "}
                          </label>
                          <textarea
                            type="text"
                            required
                            name="message"
                            placeholder="Enter Your Message"
                            className="form-control"
                          ></textarea>
                        </div>
                      </ModalBody>

                      <ModalFooter>
                        <button className="btn btn-success" type="submit">
                          Submit
                        </button>
                        <button
                          className="btn btn-danger"
                          variant="secondary"
                          onClick={hideContactUsModal}
                        >
                          Close
                        </button>
                      </ModalFooter>
                    </form>
                  </Modal>
                </div>
              );
            })}
          <SwiperComponent
            title="Similar Treks"
            classTitle="mainSectionsAttractions"
            breakpoints={{
              320: {
                slidesPerView: 1.3,
                spaceBetween: 30,
              },
              480: {
                slidesPerView: 1.5,
                spaceBetween: 50,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              768: {
                slidesPerView: 2.3,
                spaceBetween: 35,
              },
              1024: {
                slidesPerView: 3.2,
                spaceBetween: 35,
              },
              1440: {
                slidesPerView: 4.2,
                spaceBetween: 40,
              },
            }}
          >
            {mainData?.categories?.length > 0 &&
              mainData?.categories[0]?.trips?.map((sectionListDataTrips, i) => {
                if (sectionListDataTrips?._id === mainData?._id) {
                  return null;
                }
                if (!sectionListDataTrips.isActive) {
                  return null;
                }
                return (
                  <SwiperSlide key={i}>
                    <LazyLoad height={150} offset={50}>
                      <CardMedia
                        image={sectionListDataTrips?.images[0]?.link}
                        onClick={() => {
                          const url = `/tours/${sectionListDataTrips?.slug}`;
                          window.open(url, "_blank"); // This will open the URL in a new tab or window
                        }}
                        className="sectionListDataTrips"
                        title={sectionListDataTrips.title}
                      />
                      <div
                        style={{
                          position: "relative",
                          bottom: "10%",
                          height: "10px",
                        }}
                      >
                        {sectionListDataTrips?.tripLabel !== "none" && (
                          <div className="trip_label_slider">
                            {sectionListDataTrips?.tripLabel === "groupTrip"
                              ? "Group Trip"
                              : sectionListDataTrips?.tripLabel === "customised"
                              ? "Customised"
                              : null}
                          </div>
                        )}
                      </div>
                    </LazyLoad>
                    <span className="subSectionTitle">
                      {sectionListDataTrips.title}
                    </span>
                  </SwiperSlide>
                );
              })}
          </SwiperComponent>

          <Modal
            size={months && months.length > 4 ? "xl" : "l"}
            show={bookingShow}
            onHide={bookingModalClose}
          >
            <ModalHeader style={{ background: "#fff" }}>
              <ModalTitle
                style={{
                  color: "#1c1c1c",
                  textAlign: "left",
                  fontFamily: "Montserrat",
                  fontSize: "1rem",
                  fontWeight: "600",
                }}
              >
                Select Package
              </ModalTitle>
              <span
                style={{ cursor: "pointer" }}
                className="d-flex ms-auto"
                onClick={bookingModalClose}
              >
                x<i className="fe fe-x ms-auto"></i>
              </span>
            </ModalHeader>
            <ModalBody>
              <span className="joinusfromModal">Join Us From</span>
              <Row style={{ marginTop: "10px", marginLeft: "0px" }}>
                {/* <Stack spacing={2} direction="row" sx={{ marginTop: "10px" }}> */}
                {cities &&
                  cities.map((data, index) => {
                    // console.log("datadatadata", data.cityName);
                    return (
                      <div
                        key={index}
                        style={{
                          boxSizing: "border-box",
                          margin: "5px",
                        }}
                      >
                        <Button
                          onClick={() => {
                            if (selectedCity !== data.cityName) {
                              updateCity(data.cityName);
                              setDates([]);
                              setPricesArray([]);
                              setSelectedMonth("");
                              // alert("hi");
                            }
                          }}
                          className="button_onHover"
                          key={index}
                          // onClick={() => { console.log('onClick'); }}
                          sx={{
                            background: `${
                              selectedCity === data.cityName ? "#36b54a" : ""
                            }`,
                            border: "1px solid #36b54a",
                            borderRadius: "4px",
                            color: `${
                              selectedCity === data.cityName
                                ? "#FFFFFF"
                                : "#36b54a"
                            }`,
                            fontWeight: "500",
                            fontFamily: "Montserrat",
                            "&:hover": {
                              background: "#36b54a",
                              color: "#FFFFFF",
                            },
                          }}
                          tabIndex={0} // Add this property
                        >
                          {data.cityName}
                        </Button>
                      </div>
                    );
                  })}
                {/* </Stack> */}
              </Row>
              <hr />
              <span className="joinusfromModal">Select Month :</span>
              <Row
                style={{
                  marginTop: "10px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              >
                {/* <Stack
      spacing={2}
      direction="row"
      sx={{ marginTop: "10px" }}
      // style={{ overflow: "scroll", paddingBottom: "20px" }}
    > */}
                {months &&
                  months?.map((data, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          boxSizing: "border-box",
                          // width: "20%",
                          paddingBottom: "10px",
                          paddingTop: "10px",
                          marginLeft: "5px",
                          marginRight: "5px",
                        }}
                      >
                        <Button
                          onClick={() => {
                            if (selectedMonth !== data?.month_name) {
                              updateMonth(data?.month_name);
                            }
                          }}
                          key={index}
                          className="button_onHover"
                          // onClick={() => { console.log('onClick'); }}
                          sx={{
                            background: `${
                              selectedMonth === data?.month_name
                                ? "#36b54a"
                                : ""
                            }`,
                            border: "1px solid #36b54a",
                            borderRadius: "4px",
                            color: `${
                              selectedMonth === data?.month_name
                                ? "#FFFFFF"
                                : "#36b54a"
                            }`,
                            fontWeight: "500",
                            fontFamily: "Montserrat",
                          }}
                          tabIndex={0} // Add this property
                        >
                          {data?.month_name}
                        </Button>
                      </div>
                    );
                  })}
                {/* </Stack> */}
              </Row>
              <hr />

              <span className="joinusfromModal">Select Date</span>
              <div className="row">
                {dates &&
                  dates.map((data, index) => {
                    const key = Object.keys(data)[0];
                    const value = Object.values(data)[0];
                    const date = moment(value, "DD-MM-YYYY");
                    const totalHours = durationInHours;

                    // Add 48 hours to the date
                    const newDate = date.add(totalHours, "hours");

                    // Format the new date as a string in the same format
                    const newDateFormatted = newDate.format("DD-MM-YYYY");
                    return (
                      <div
                        key={index}
                        className={`${
                          months && months.length > 4 ? "col-lg-4" : "col-lg-12"
                        } mb-2 mt-4`}
                        onClick={() => {
                          // console.log("key", key);
                          setSelectedDateNew(value);
                          updatDate(key);
                          handleUpdateUserInformation(key, value);
                          scrollToSelectPrice();
                          // navigate("/CustomerLogin");
                        }}
                      >
                        <div
                          style={{
                            width: "90%",
                            border: `2px solid ${
                              selectedDate === data.date ? "#36b54a" : "#E3E3E3"
                            }`,
                            background: `${
                              selectedDateNew === value ? "#36b54a" : ""
                            }`,
                            color: `${
                              selectedDateNew === value ? "#FFFFFF" : "#36b54a"
                            }`,
                            borderRadius: "10px",
                            padding: "10px",

                            cursor: "pointer",
                          }}
                        >
                          <h6
                            className="bookingFooterModalsTextModal"
                            style={{
                              // float: "left",
                              marginBottom: "0px",
                              marginRight: "10px",
                              color: `${
                                selectedDateNew === value ? "#FFFFFF" : "#111"
                              }`,
                            }}
                          >
                            <strong>{value}</strong> to{" "}
                            <strong>{newDateFormatted}</strong>
                          </h6>
                          {/* <Iconfiy
                            style={{ marginBottom: "4px" }}
                            width="18"
                            height="20"
                            color="#1C1C1C"
                            icon="uil:arrow-right"
                          /> */}
                        </div>
                      </div>
                    );
                  })}
              </div>
              <hr />
              <span ref={selectPriceRef} className="joinusfromModal">
                Select Price :
              </span>
              <Stack spacing={2} direction="row" sx={{ marginTop: "10px" }}>
                {/* {cities.map((city, i) => {
        if (city.cityName === selectedCity) {
          return (
            <div
              key={i}
              style={{
                boxSizing: "border-box",
              }}
            >
              {city.prices.map((price2, j) => (
                <Button
                  onClick={() => {
                    updatePrice(price2.price, price2.title);
                  }}
                  key={j}
                  sx={{
                    background: `${
                      price === price2.price ? "#36b54a" : ""
                    }`,
                    border: "1px solid #36b54a",
                    borderRadius: "4px",
                    color: `${
                      price === price2.price ? "#FFFFFF" : "#36b54a"
                    }`,
                    fontWeight: "500",
                    marginLeft: "5px",
                    marginTop: "5px",

                    fontFamily: "Montserrat",
                    "&:hover": {
                      background: "#36b54a",
                      color: "#FFFFFF",
                    },
                  }}
                  tabIndex={0} // Add this property
                >
                  {city.cityName} - {price2.title} / ₹{price2.price}
                </Button>
              ))}
            </div>
          );
        } else {
          return null;
        }
      })} */}

                <div
                  style={{
                    boxSizing: "border-box",
                  }}
                >
                  {pricesArray.length > 0 ? (
                    pricesArray?.map((price2, j) => (
                      <Button
                        onClick={() => {
                          updatePrice(price2.price, price2.title);
                          // goToCart();
                        }}
                        className="button_onHover"
                        key={j}
                        sx={{
                          background: `${
                            price === price2.price ? "#36b54a" : ""
                          }`,
                          border: "1px solid #36b54a",
                          borderRadius: "4px",
                          color: `${
                            price === price2.price ? "#FFFFFF" : "#36b54a"
                          }`,
                          fontWeight: "500",
                          marginLeft: "5px",
                          marginTop: "5px",

                          fontFamily: "Montserrat",
                          "&:hover": {
                            background: "#36b54a",
                            color: "#FFFFFF",
                          },
                        }}
                        tabIndex={0} // Add this property
                      >
                        {selectedCity} - {price2.title} / ₹{price2.price}
                      </Button>
                    ))
                  ) : (
                    <span>Please Select a Date</span>
                  )}
                </div>
              </Stack>
              <hr />
            </ModalBody>
            {/* <ModalFooter>
    <Button variant="secondary" onClick={bookingModalClose}>
      Close
    </Button>
  </ModalFooter> */}
          </Modal>
        </>
      )}
    </div>
  );
};
