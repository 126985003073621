import React, { useCallback, useEffect, useState } from "react";
import {
  Image,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import { getContacts } from "../api";
import { toast } from "react-toastify";
import requestCallback from "../assets/requestCallback.svg"
import whatsapp from "../assets/whatsapp.svg";
import callNow from "../assets/callNow.png"
import instagram from "../assets/instagram.png"
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const WhatsappBtn = () => {
  const [largeShow, setLargeShow] = useState(false);
  const [contactModalState, setContactModalState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const setErrorToast = useCallback(
    (errorFromBackend) => {
      toast.error(
        <p className="text-white tx-16 mb-0" style={{ zIndex: 200000 }}>
          Error: {error.length > 0 ? error : errorFromBackend}
        </p>,
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          autoClose: 2000,
          theme: "colored",
        }
      );
    },
    [error]
  );

  const modalClose = () => setLargeShow(false);
  const modalShow = () => {
    setLargeShow(true);
  };

  const contactModalClose = () => setContactModalState(false);
  const contactModalShow = () => setContactModalState(true);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const apiData = await getContacts();
        if (apiData.error) {
          setError(apiData.error);
        } else {
          // console.log("getContacts", apiData);
          setContacts(apiData.data);
        }
      } catch (err) {
        console.log("err", err.name);
        if (err.response) {
          setError(err.response.data.message);
          setErrorToast(err.response.data.message);
          navigate(`/errorPage/${err.response.data.message}`);
        } else if (err.name === "AxiosError") {
          setError(err.message);
          setErrorToast(err.message);
          navigate(`/errorPage/${err.message}`);
        } else {
          setError("something went wrong");
          setErrorToast("something went wrong");
        }
      }
      setLoading(false);
    };
    getData();
  }, [navigate, setErrorToast]);

  return (
    <>
      <div className="whatsappBtn">
        <img src="whatsapp.png" alt="whatsapp" onClick={modalShow} />
      </div>
      <Modal size="sm" show={largeShow} onHide={modalClose}>
        <ModalHeader>
          <ModalTitle>Contact</ModalTitle>
          <span
            style={{ cursor: "pointer" }}
            className="d-flex ms-auto"
            onClick={modalClose}
          >
            {/* <i className="fe fe-x ms-auto"></i> */}X
          </span>
        </ModalHeader>
        <ModalBody>
          <Link to={`${process.env.PUBLIC_URL}/admin/ContactUs`}>
            <Image src={requestCallback} loading="lazy" height="50px" />
            <span style={{ paddingLeft: "20px" }}>Request a Callback</span>
            <hr />
          </Link>
          <Link to={`https://wa.me/918447937907`} target="_blank">
            <Image src={whatsapp} loading="lazy" height="50px" />
            <span style={{ paddingLeft: "20px" }}>Whatsapp</span>
            <hr />
          </Link>
          <Link onClick={()=>{contactModalShow();modalClose();}}>
            <Image
              src={callNow}
              loading="lazy"
              style={{ height: "50px", filter: "invert(1)" }}
            />
            <span style={{ paddingLeft: "20px" }}>Call Now</span>
            <hr />
          </Link>
          <Link
            to={`https://www.instagram.com/trek_panda/?hl=en`}
            target="_blank"
          >
            <Image src={instagram} loading="lazy" height="50px" />
            <span style={{ paddingLeft: "20px" }}>Instagram</span>
          </Link>
          {/* {contacts && contacts?.map((phoneNosData, i)=>{
                return (
                  <div key={i}>
                    {loading ? (
                      <Skeleton
                      key={i}
                        variant="rectangular"
                        sx={{ marginTop: "5px" }}
                        // width={450}
                        height={100}
                      />
                    ) : (
                      <>
                        <div key={i}>
                          <p>{phoneNosData?.title}</p>
                          <a href={`tel:${phoneNosData?.contactNum1}`}>
                            {phoneNosData?.contactNum1}
                          </a>{" "}
                          <br />
                          <a href={`tel:${phoneNosData?.contactNum2}`}>
                            {phoneNosData?.contactNum2}
                          </a>
                        </div>
                        <hr />
                      </>
                    )}
                  </div>
                );
            })} */}

          {/* <div>
            <p></p>
            <a href="tel:918447937907"></a>
          </div>
          <hr />
          <div>
            <p></p>
            <a href="tel:+91- 8669021690">+91- </a>
          </div>
          <hr />
          <div>
            <p></p>
            <a href="tel:+91- 7020216734">+91- </a>
          </div> */}
        </ModalBody>
      </Modal>
      {/* Contact Modal */}
      <Modal size="md" show={contactModalState} onHide={()=>{contactModalClose();modalShow();}}>
        <ModalHeader>
          <ModalTitle>Contact</ModalTitle>
          <span
            style={{ cursor: "pointer" }}
            className="d-flex ms-auto"
            onClick={()=>{contactModalClose();modalShow();}}
          >
            {/* <i className="fe fe-x ms-auto"></i> */}X
          </span>
        </ModalHeader>
        <ModalBody>
          {contacts &&
            contacts?.map((phoneNosData, i) => {
              return (
                <div key={i}>
                  {loading ? (
                    <Skeleton
                      key={i}
                      variant="rectangular"
                      sx={{ marginTop: "5px" }}
                      // width={450}
                      height={100}
                    />
                  ) : (
                    <>
                      <div key={i}>
                        <p>{phoneNosData?.title}</p>
                        <a href={`tel:${phoneNosData?.contactNum1}`}>
                          {phoneNosData?.contactNum1}
                        </a>{" "}
                        <br />
                        <a href={`tel:${phoneNosData?.contactNum2}`}>
                          {phoneNosData?.contactNum2}
                        </a>
                      </div>
                      <hr />
                    </>
                  )}
                </div>
              );
            })}
        </ModalBody>
      </Modal>
    </>
  );
};

export default WhatsappBtn;
