import React, { useEffect, useState } from "react";
// import Button from "@mui/material/Button";
// import { Icon as Iconfiy } from "@iconify/react";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
// import Drawer from "@mui/material/Drawer";
// import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
// import Typography from "@mui/material/Typography";
// import TestimonialCard from "material-testimonial-card";
import { ToastContainer } from "react-toastify";
import { allTrips } from "../api";
// import RevoCalendar from "revo-calendar";
import moment from "moment";
// import TripCardView from "../component/TripCardView";
import CircularProgress from "@mui/material/CircularProgress";
// import animation from "../assets/animation_cal.gif";
import { Col, Row } from "react-bootstrap";

export const SearchAllTrips = () => {
  const [mainData, setMainData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [selectedDate, setSelectedDate] = useState(Date.now());
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState("");
  const [error, setError] = useState("");
  const [eventsData, setEventsData] = useState([]);
  const navigate = useNavigate();
  // const convertDateToMilliseconds = (dateObject) => {
  //   const { day, month, year } = dateObject;
  //   const date = new Date(year, month + 1 - 1, day);
  //   return date.getTime();
  // };
  const handleSearchButtonClick = (e) => {
    e.preventDefault();
    setLoading(true);
    const filteredData = mainData.filter((item) => {
      return (
        item.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.about?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.sub_title?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    setSearchResults(filteredData);
    setLoading(false);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    // console.log("search result", filteredData);
  };

  //   const dateSelectedEvent = async (title) => {
  //     setLoading(true);
  //     // setMainData([]);
  //     // getData();
  //     let dateNew = convertDateToMilliseconds(title); // Assuming dateNew is in milliseconds
  //     let formattedDate = moment(dateNew).format("DD-MM-YYYY");
  //     const data = await getTripsByCategoryId(id);
  //     if (data.data) {
  //       let xyz = findEventsByDate(data.data, formattedDate);
  //     //   console.log("xyz", xyz);
  //       setSelectedDate(dateNew);
  //       if (xyz.length > 0) {
  //         // console.log("setting maindata");
  //         setMainData(xyz);
  //       } else {
  //         // console.log("clearning maindata");

  //         setMainData([]);
  //       }
  //     }
  //     setLoading(false);
  //   };
  //   function findEventsByDate(events, dateToFind) {
  //     const foundEvents = [];

  //     events.forEach((event) => {
  //       let flag = false;
  //       event.cities.forEach((city) => {
  //         if (Array.isArray(city.dates_new)) {
  //           city.dates_new.forEach((dateInfo) => {
  //             if (dateInfo.date === dateToFind) {
  //               flag = true;
  //             }
  //           });
  //         } else if (city.dates_new && city.dates_new.date === dateToFind) {
  //           flag = true;
  //         }
  //       });
  //       console.log("flag", flag);
  //       if (flag === true) {
  //         foundEvents.push(event);
  //       }
  //     });

  //     return foundEvents;
  //   }
  const getDates = (events) => {
    // console.log("getDates called");
    var newEvents = [];
    const currentDate = new Date(); // Get the current date and time

    events.forEach(function (event) {
      //   console.log("foreach called");

      event.cities.forEach(function (city) {
        // console.log("cities called", city);

        if (Array.isArray(city.dates_new)) {
          // Handle the case where dates_new is an array of dates
          city.dates_new.forEach(function (dateInfo) {
            if (dateInfo.date) {
              //   console.log("city date found", dateInfo);

              const formattedTimestamp = moment(
                dateInfo.date,
                "DD-MM-YYYY"
              ).valueOf(); // Convert to Unix timestamp in milliseconds

              if (formattedTimestamp > currentDate.getTime()) {
                newEvents.push({
                  title: event.title,
                  city: city.city_name,
                  date: formattedTimestamp,
                  allDay: event.allDay || false,
                });
              }
            } else {
              console.log("no dates found");
            }
          });
        } else if (city.dates_new && city.dates_new.date) {
          // Handle the case where dates_new is a single date
          //   console.log("city date found", city.dates_new);9

          const formattedDate = moment(
            city.dates_new.date,
            "DD-MM-YYYY"
          ).valueOf();

          newEvents.push({
            title: event.title,
            city: city.city_name,
            date: formattedDate,
            allDay: event.allDay || false,
          });
        } else {
          console.log("no dates found");
        }
      });
    });

    return newEvents;
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const data = await allTrips();
        if (data.data) {
          // console.log(data.data, "allTrips");
          setMainData(data.data);
          let abc = getDates(data.data);
          if (abc) {
            setEventsData(abc);
            //   console.log("abc", abc);
          }
        } else {
          setError("Something Went Wrong !! ");
        }
      } catch (err) {
        //   console.log("err12123", err);
        if (err.response) {
          setError(err.response.data.message);
        } else {
          setError(err.message);
        }
      }
      setLoading(false);
    };
    getData();
  }, []);
  // const setErrorToast = (errorFromBackend) => {
  //   toast.error(
  //     <p className="text-white tx-16 mb-0" style={{ zIndex: 200000 }}>
  //       Error: {error.length > 0 ? error : errorFromBackend}
  //     </p>,
  //     {
  //       position: toast.POSITION.TOP_RIGHT,
  //       hideProgressBar: true,
  //       autoClose: 2000,
  //       theme: "colored",
  //     }
  //   );
  // };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          height: "168.8px",
          left: "0px",
          top: "0px",
          background: "#36b54a",
        }}
      >
        <h2 className="tripsByCategoryText">Search All Trips</h2>
        <ToastContainer />
      </div>

      <div className="phoneNumberDivLogin">
        <div className="row">
          <div className="col-lg-12 mt-5">
            <form method="post" onSubmit={handleSearchButtonClick}>
              <Row>
                <Col lg={8}>
                  {/* <div className="form-group"> */}
                  <input
                    type="text"
                    name="searchTrips"
                    id="searchTrips"
                    className="form-control form-control-lg"
                    placeholder="Search Trips"
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                  {/* </div> */}
                </Col>
                <button
                  type="submit"
                  className="btn btn-success margin_top_3_mobile"
                >
                  Search
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setSearchResults("");
                    setSearchQuery("");
                  }}
                  className="btn btn-warning mx-3 margin_top_3_mobile"
                >
                  Reset
                </button>
              </Row>
            </form>
          </div>
          {loading && (
            <div className="col-lg-12 p-2 text-center">
              <CircularProgress size={60} style={{ color: "#36b54a" }} />
            </div>
          )}
          {searchResults ? (
            searchResults.length !== 0 ? (
              searchResults.map((data, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      cursor: "pointer",
                    }}
                    className={`col-lg-4 mt-5`}
                    onClick={() => {
                      navigate(`/tours/${data?.slug}`);
                    }}
                  >
                    <Card className=" eventsCards">
                      <CardMedia
                        sx={{
                          height: "180px",
                          width: "100%",
                          borderRadius: "11px",
                        }}
                        image={data?.images[0]?.link}
                        title={data?.title}
                      />
                      {data?.tripLabel !== "none" && (
                        <div className="tripLabel">
                          {data?.tripLabel === "groupTrip"
                            ? "Group Trip"
                            : data?.tripLabel === "customised"
                            ? "Customised"
                            : null}
                        </div>
                      )}

                      <span className="tripbycategoryTitle">{data?.title}</span>
                      <br />
                      <span className="tripbycategorySubTitle">
                        From ₹ {data?.cities[0]?.prices[0]?.price}/ -{" "}
                        {data?.cities[0]?.duration}
                      </span>
                    </Card>
                  </div>
                );
              })
            ) : (
              <h1 className="my-5">No Trip Found</h1>
            )
          ) : (
            mainData &&
            mainData.map((data, i) => {
              return (
                <div
                  key={i}
                  style={{ cursor: "pointer" }}
                  className="col-lg-4 mt-5"
                  onClick={() => {
                    navigate(`/tours/${data?.slug}`);
                  }}
                >
                  <Card className="eventsCards">
                    <CardMedia
                      sx={{
                        height: "180px",
                        width: "100%",
                        borderRadius: "11px",
                      }}
                      image={data?.images[0]?.link}
                      title={data?.title}
                    />
                    {data?.tripLabel !== "none" && (
                      <div className="tripLabel">
                        {data?.tripLabel === "groupTrip"
                          ? "Group Trip"
                          : data?.tripLabel === "customised"
                          ? "Customised"
                          : null}
                      </div>
                    )}
                    <span className="tripbycategoryTitle">{data?.title}</span>
                    <br />
                    <span className="tripbycategorySubTitle">
                      From ₹ {data?.cities[0]?.prices[0]?.price}/ -{" "}
                      {data?.cities[0]?.duration}
                    </span>
                  </Card>
                </div>
              );
            })
          )}

          {!mainData && <h1>No Result Found!</h1>}
        </div>
      </div>
    </div>
  );
};
