import React, { useEffect, useState } from "react";
import { Layout } from "./component/Layout";
import Home from "./pages/Home";
import { About } from "./pages/About";
import { CancellationPolicy } from "./pages/CancellationPolicy";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { Blogs } from "./pages/Blogs";
import { SearchAllTrips } from "./pages/SearchAllTrips";
import { Blogs_deials } from "./pages/Blogs_deials";
import { Teams } from "./pages/Teams";
import { Contact } from "./pages/Contact";
import { Careers } from "./pages/Careers";
import { Events } from "./component/Events";
import ContactUs from "./pages/ContactUs";
import { LayoutCustomer } from "./component/LayoutCustomer";
import { UserDashboard } from "./pages/UserDashboard";
import { EventDetails } from "./pages/EventDetails";
import { CustomerLogin } from "./pages/CustomerLogin";
import { OrderPlaced } from "./pages/OrderPlaced";
import { OrderCancelled } from "./pages/OrderCancelled";
import { TripByCategory } from "./pages/TripByCategory";
import { SearchTrips } from "./pages/SearchTrips";
import { CustomerRegistration } from "./pages/CustomerRegistration";
import { PartialPay } from "./pages/PartialPay";
import { CustomerPayNowScreen } from "./pages/CustomerPayNowScreen";
import { LayoutNew } from "./component/LayoutNew";
import { LayoutNewLogin } from "./component/LayoutNewLogin";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import ScrollToTop from "./component/ScrollToTop";
import "./App.css";
import NoInternet from "./pages/NoInternet";
import ErrorPage from "./pages/ErrorPage";

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ScrollToTop />
          {isOnline ? (
            <Routes>
              <Route path="/CustomerLogin" element={<LayoutNewLogin />}>
                <Route index element={<CustomerLogin />} />
              </Route>
              <Route path="/OrderPlaced" element={<LayoutNewLogin />}>
                <Route index element={<OrderPlaced />} />
              </Route>
              <Route path="/OrderCancelled" element={<LayoutNewLogin />}>
                <Route index element={<OrderCancelled />} />
              </Route>
              <Route
                path="/category/:id/:name/:cal"
                element={<LayoutNewLogin />}
              >
                <Route index element={<TripByCategory />} />
              </Route>
              <Route path="/SearchTrips" element={<LayoutNewLogin />}>
                <Route index element={<SearchTrips />} />
              </Route>
              <Route path="/SearchAllTrips" element={<LayoutNewLogin />}>
                <Route index element={<SearchAllTrips />} />
              </Route>
              <Route path="/About" element={<LayoutNew />}>
                <Route index element={<About />} />
              </Route>
              <Route path="/CancellationPolicy" element={<LayoutNew />}>
                <Route index element={<CancellationPolicy />} />
              </Route>
              <Route path="/PrivacyPolicy" element={<LayoutNew />}>
                <Route index element={<PrivacyPolicy />} />
              </Route>
              <Route path="/Blogs" element={<LayoutNew />}>
                <Route index element={<Blogs />} />
              </Route>
              <Route path="/blogs_details/:id/:name" element={<LayoutNew />}>
                <Route index element={<Blogs_deials />} />
              </Route>
              <Route path="/Contact" element={<LayoutNew />}>
                <Route index element={<Contact />} />
              </Route>
              <Route path="/Careers" element={<LayoutNew />}>
                <Route index element={<Careers />} />
              </Route>
              <Route path="/Teams" element={<LayoutNew />}>
                <Route index element={<Teams />} />
              </Route>
              <Route path="/errorPage/:error" element={<ErrorPage />} />
              <Route path="/CustomerRegistration" element={<LayoutNewLogin />}>
                <Route index element={<CustomerRegistration />} />
              </Route>
              <Route path="/PartialPay/:id" element={<LayoutNewLogin />}>
                <Route index element={<PartialPay />} />
              </Route>
              <Route path="/customerPayNowScreen" element={<LayoutNewLogin />}>
                <Route index element={<CustomerPayNowScreen />} />
              </Route>
              <Route path="/" element={<LayoutNew />}>
                <Route index element={<Home />} />
                <Route
                  path={`${process.env.PUBLIC_URL}/tours/:id`}
                  element={<EventDetails />}
                />
              </Route>
              <Route path="/admin" element={<Layout />}>
                <Route index element={<Home />} />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin/About`}
                  element={<About />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin/About`}
                  element={<About />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin/Events`}
                  element={<Events />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin/ContactUs`}
                  element={<ContactUs />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin/EventDetails/:id`}
                  element={<EventDetails />}
                />
              </Route>
              <Route path="/user" element={<LayoutCustomer />}>
                <Route index element={<UserDashboard />} />
              </Route>
              {/* <Route path="/customer" element={<LayoutCustomer />}>
          <Route index element={<UserDashboard />} />
        </Route> */}
            </Routes>
          ) : (
            <NoInternet />
          )}
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
