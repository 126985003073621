import React, { useState, useEffect, useRef } from "react";
import { Outlet, Link } from "react-router-dom";
import "../LocationPopup.css";
import dataJson from "./cities.json";
import { BiCurrentLocation } from "react-icons/bi";
import logo from "../logo.png";
export const Layout = () => {
  const [selectCityModal, setSelectCityModal] = useState(false);
  const [selectedCity, setSelectedcity] = useState("Pune");
  const [cities, setCities] = useState([]);
  const headerRef = useRef(null);

  
  useEffect(() => {
    setCities(dataJson.cities);
    // console.log("cites2", cities);
    // return () => {
    //   second
    // }
  }, [cities]);

  const updateCity = (city) => {
    setSelectedcity(city);
    setSelectCityModal(false);
  };
  const toggleModal = () => {
    setSelectCityModal(!selectCityModal);
    headerRef.current.scrollTo(0, 0);
  };
  return (
    <body>
      {selectCityModal === true ? (
        <section style={{ opacity: 1, zIndex: 200000 }}>
          <div id="location-popup">
            <div className="container">
              <div className="row">
                <div className="col-lg-11 col-md-11 col-sm-11">
                  <div id="">
                    Select Your Journey Location !2
                  </div>
                  {/* <span className="myAnuj"> POPULAR CITY</span> */}
                </div>
                <div
                  className="col-lg-1 col-md-1 col-sm-11 mt-4 text-right"
                  onClick={toggleModal}
                >
                  <span className="float-right">
                    <i className="fal fa-times" />
                  </span>
                </div>
              </div>
            </div>

            <div id="location-popup-city">
              <div id="location-popup-city-header">POPULAR CITY</div>
              <div id="top-city-locations">
                {cities.length > 0 &&
                  cities?.map((item, i) => {
                    return (
                      <div
                        key={i}
                        onClick={() => {
                          updateCity(item.name);
                        }}
                        id={i}
                      >
                        <img src={item.icon} width="60px" alt />
                        <div className="text-center">{item.name}</div>
                      </div>
                    );
                  })}

                {/* <div onclick="locationUpdate(id)" id="Bangalore">
                  <img
                    src="https://cdn-images.cure.fit/www-curefit-com/image/upload/fl_progressive,f_auto,q_auto:eco,w_50,h_50,ar_1/dpr_2/image/cities/blr_new.png"
                    width="60px"
                    alt
                  />
                  <div>Bangalore</div>
                </div>
                <div onclick="locationUpdate(id)" id="Delhi NCR">
                  <img
                    src="https://cdn-images.cure.fit/www-curefit-com/image/upload/fl_progressive,f_auto,q_auto:eco,w_50,h_50,ar_1/dpr_2/image/cities/ncr_new.png"
                    width="60px"
                    alt
                  />
                  <div>Delhi NCR</div>
                </div>
                <div onclick="locationUpdate(id)" id="Hyderabad">
                  <img
                    src="https://cdn-images.cure.fit/www-curefit-com/image/upload/fl_progressive,f_auto,q_auto:eco,w_50,h_50,ar_1/dpr_2/image/cities/hyd_new.png"
                    width="60px"
                    alt
                  />
                  <div>Hyderabad</div>
                </div>
                <div onclick="locationUpdate(id)" id="Mumbai">
                  <img
                    src="https://cdn-images.cure.fit/www-curefit-com/image/upload/fl_progressive,f_auto,q_auto:eco,w_50,h_50,ar_1/dpr_2/image/cities/mumbai_selected.png"
                    width="60px"
                    alt
                  />
                  <div>Mumbai</div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      ) : null}

      <div style={{ opacity: selectCityModal ? 0.2 : 1 }} ref={headerRef}>
        <header className="header-area">
          <div className="header-top-bar padding-right-100px padding-left-100px">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="header-top-content">
                    <div className="header-left">
                      <ul className="list-items">
                        <li>
                          <a href="#">
                            <i className="la la-phone mr-1" />
                            +91 8447937907
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="la la-envelope mr-1" />
                            trekpaanda@gmail.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="header-top-content">
                    <div className="header-right d-flex align-items-center justify-content-end">
                      <div className="header-right-action">
                        <a
                          href="#"
                          className="theme-btn theme-btn-small theme-btn-transparent mr-1"
                          data-toggle="modal"
                          data-target="#loginPopupForm"
                        >
                          Login
                        </a>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-menu-wrapper padding-right-100px padding-left-100px">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="menu-wrapper">
                    <a href="#" className="down-button">
                      <i className="la la-angle-down" />
                    </a>
                    <div className="logo">
                      <a href="index.html">
                        <img src={logo} style={{ height: "85px" }} alt="logo" />
                      </a>
                      <div className="menu-toggler">
                        <i className="la la-bars" />
                        <i className="la la-times" />
                      </div>
                      {/* end menu-toggler */}
                    </div>
                    {/* end logo */}
                    <div className="main-menu-content">
                      <nav>
                        <ul>
                          <li className="active">
                            <a href="/">Home</a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/Events`}>
                              Events
                            </a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/About`}>
                              About
                            </a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/ContactUs`}>
                              Contact Us
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    {/* end main-menu-content */}
                    <div className="nav-btn">
                      <button
                        href="become-local-expert.html"
                        className="theme-btn"
                        onClick={toggleModal}
                      >
                        <BiCurrentLocation /> {selectedCity}
                      </button>
                    </div>
                    {/* end nav-btn */}
                  </div>
                  {/* end menu-wrapper */}
                </div>
                {/* end col-lg-12 */}
              </div>
              {/* end row */}
            </div>
            {/* end container-fluid */}
          </div>
        </header>

        <Outlet />

        <section className="footer-area section-bg padding-top-100px padding-bottom-30px">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 responsive-column">
                <div className="footer-item">
                  <div className="footer-logo padding-bottom-30px">
                    <a href="index.html" className="foot__logo">
                      <img src={logo} style={{ height: "85px" }} alt="logo" />
                    </a>
                  </div>
                  {/* end logo */}
                  <p className="footer__desc">
                  At TrekPanda, we strongly believe in protecting consumer privacy. That's why we only ask you for information related to the services we provide.                   </p>
                  <ul className="list-items pt-3">
                    <li>
                      Pune : 8447937907 <br /> Delhi/north : 7020216734  <br /> Mumbai : 9653272960 <br />
                      Bangalore : 9284544815
                    </li>
                    <li>
                      <a href="#">trekpaanda@gmail.com</a>
                    </li>
                  </ul>
                </div>
                {/* end footer-item */}
              </div>
              {/* end col-lg-3 */}
              <div className="col-lg-3 responsive-column">
                <div className="footer-item">
                  <h4
                    className="title curve-shape pb-3 margin-bottom-20px"
                    data-text="curvs"
                  >
                    Company
                  </h4>
                  <ul className="list-items list--items">
                    <li>
                      <a href="about.html">Events</a>
                    </li>
                    <li>
                      <a href="services.html">Contact Us</a>
                    </li>
                    <li>
                      <a href="#">About Us</a>
                    </li>
                    <li>
                      <a href="blog-grid.html">Jobs</a>
                    </li>
                    {/* <li>
                      <a href="contact.html">Support</a>
                    </li>
                    <li>
                      <a href="#">Advertising</a>
                    </li> */}
                  </ul>
                </div>
                {/* end footer-item */}
              </div>
              {/* end col-lg-3 */}
              <div className="col-lg-3 responsive-column">
                <div className="footer-item">
                  <h4
                    className="title curve-shape pb-3 margin-bottom-20px"
                    data-text="curvs"
                  >
                    Quick Links
                  </h4>
                  <ul className="list-items list--items">
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="#">Cancellation Policy</a>
                    </li>
                    <li>
                      <a href="#">Terms & Conditions</a>
                    </li>
                    <li>
                      <a href="#">Disclaimer</a>
                    </li>
                    <li>
                      <a href="#">About Us</a>
                    </li>
                    {/* <li>
                      <a href="#">Trizen Reviews</a>
                    </li> */}
                  </ul>
                </div>
                {/* end footer-item */}
              </div>
              {/* end col-lg-3 */}
              <div className="col-lg-3 responsive-column">
                <div className="footer-item">
                  <h4
                    className="title curve-shape pb-3 margin-bottom-20px"
                    data-text="curvs"
                  >
                    Subscribe now
                  </h4>
                  <p className="footer__desc pb-3">
                    Subscribe for latest updates &amp; promotions
                  </p>
                  <div className="contact-form-action">
                    <form action="#">
                      <div className="input-box">
                        <label className="label-text">
                          Enter email address
                        </label>
                        <div className="form-group mb-0">
                          <span className="la la-envelope form-icon" />
                          <input
                            className="form-control"
                            type="email"
                            name="email"
                            placeholder="Email address"
                          />
                          <button
                            className="theme-btn theme-btn-small submit-btn"
                            type="submit"
                          >
                            Go
                          </button>
                          <span className="font-size-14 pt-1">
                            <i className="la la-lock mr-1" />
                            Your information is safe with us.
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/* end footer-item */}
              </div>
              {/* end col-lg-3 */}
            </div>
            {/* end row */}
            <div className="row align-items-center">
              {/* <div className="col-lg-8">
                <div className="term-box footer-item">
                  <ul className="list-items list--items d-flex align-items-center">
                    <li>
                      <a href="#">Terms &amp; Conditions</a>
                    </li>
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="#">Help Center</a>
                    </li>
                  </ul>
                </div>
              </div> */}
              {/* end col-lg-8 */}
              <div className="col-lg-12">
                <div className="footer-social-box text-right">
                  <ul className="social-profile">
                    <li>
                      <a href="#">
                        <i className="lab la-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="lab la-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="lab la-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="lab la-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* end col-lg-4 */}
            </div>
            {/* end row */}
          </div>
          {/* end container */}
          <div className="section-block mt-4" />
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="copy-right padding-top-30px">
                  <p className="copy__desc">
                    © Copyright TrekPanda 2023. Made with
                    <span className="la la-heart" /> by 
                    <Link to="/">
                     TrekPanda
                    </Link>
                  </p>
                </div>
                {/* end copy-right */}
              </div>
              {/* end col-lg-7 */}
              <div className="col-lg-5">
                <div className="copy-right-content d-flex align-items-center justify-content-end padding-top-30px">
                  <h3 className="title font-size-15 pr-2">We Accept</h3>
                  <img src="images/payment-img.png" alt />
                </div>
                {/* end copy-right-content */}
              </div>
              {/* end col-lg-5 */}
            </div>
            {/* end row */}
          </div>
          {/* end container */}
        </section>
        <div id="back-to-top">
          <i className="la la-angle-up" title="Go top" />
        </div>
      </div>
      <div className="modal-popup">
        <div
          className="modal"
          id="loginPopupForm"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title title" id="exampleModalLongTitle2">
                    Login
                  </h5>
                  <p className="font-size-14">Hello! Welcome to your account</p>
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" className="la la-close" />
                </button>
              </div>
              <div className="modal-body">
                <div className="contact-form-action">
                  <form method="post">
                    <div className="input-box">
                      <label className="label-text">Username</label>
                      <div className="form-group">
                        <span className="la la-user form-icon" />
                        <input
                          className="form-control"
                          type="text"
                          name="text"
                          placeholder="Type your username"
                        />
                      </div>
                    </div>
                    {/* end input-box */}
                    <div className="input-box">
                      <label className="label-text">Password</label>
                      <div className="form-group mb-2">
                        <span className="la la-lock form-icon" />
                        <input
                          className="form-control"
                          type="password"
                          name="text"
                          placeholder="Type your password"
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="custom-checkbox mb-0">
                          <input type="checkbox" id="rememberchb" />
                          <label htmlFor="rememberchb">Remember me</label>
                        </div>
                        <p className="forgot-password">
                          <a href="recover.html">Forgot Password?</a>
                        </p>
                      </div>
                    </div>
                    {/* end input-box */}
                    <div className="btn-box pt-3 pb-4">
                      <a href="/user" className="theme-btn w-100">
                        Login Account
                      </a>
                    </div>
                    <div className="action-box text-center">
                      <p className="font-size-14">Or Login Using</p>
                      <ul className="social-profile py-3">
                        <li>
                          <a href="#" className="bg-5 text-white">
                            <i className="lab la-facebook-f" />
                          </a>
                        </li>
                        <li>
                          <a href="#" className="bg-6 text-white">
                            <i className="lab la-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#" className="bg-7 text-white">
                            <i className="lab la-instagram" />
                          </a>
                        </li>
                        <li>
                          <a href="#" className="bg-5 text-white">
                            <i className="lab la-linkedin-in" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
                {/* end contact-form-action */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};
