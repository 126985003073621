import Button from "@mui/material/Button";
import React, { useEffect, useState, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { addToCartCustomer, checkCoupons, getPriceInfo } from "../api";
import { updateUserInformation } from "../redux/actions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export const CustomerPayNowScreen = () => {
  const [isChecked, setIsChecked] = useState(false);

  const [finalAmount, setFinalAmount] = useState(0);
  const [promocodeUsed, setPromocodeUsed] = useState("");
  const [promocodeError, setPromocodeError] = useState("");
  const [promocodeAmount, setPromocodeAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [totalCapacity, setTotalCapacity] = useState(0);
  const navigate = useNavigate();
  const addToCartCustomerFunction = async (body) => {
    try {
      const apiData = await addToCartCustomer(body);
      if (apiData.error) {
        console.error("error", apiData.error);
      } else {
      }
    } catch (err) {
      console.log("err", err.name);
    }
  };
  const userInfo = useSelector((state) => state.userInformation);
  console.log("userInfo", userInfo);
  // console.log("userInfo?.selectedAddOn", userInfo?.selectedAddOn);
  // console.log("userInfo?.participant_count", userInfo?.participant_count);
  if (userInfo?.trip_name?.length === 0) {
    navigate("/");
  }

  const participant = userInfo?.participant_count || 1;
  const trip_amount = userInfo?.trip_amount || 0;
  const addOnTotal =
    userInfo?.selectedAddOn?.length > 0
      ? userInfo?.selectedAddOn?.reduce((total, data) => {
          // console.log("selectedAddOn data", data.total);
          return total + data.total;
        }, 0)
      : 0;
  const gstValue = 5;
  let tcs = 0;
  const trip_tcs = userInfo?.trip_tcs;
  let total_fees =
    Number(participant) * Number(trip_amount) + Number(addOnTotal);
  // setFinalAmount(total_fees);
  const gst = ((finalAmount * gstValue) / 100).toFixed(0);
  if (userInfo?.trip_tcs) {
    tcs = ((finalAmount * trip_tcs) / 100).toFixed(0);
    total_fees = finalAmount + tcs;
  }
  const [coupon, setCoupon] = useState("");
  const dispatch = useDispatch();
  const customer_id = localStorage.getItem("customer_id");
  useEffect(() => {
    if (!customer_id || !userInfo?.trip_id) {
      navigate("/");
    } else {
      let obj = {
        tripId: userInfo?.trip_id,
        userID: localStorage.getItem("user_id"),
        city: userInfo?.city_id,
        tripTitle: userInfo?.trip_name,
        date: userInfo?.trip_date,
        participant_count: 1,
      };
      // console.log("obj....", obj);
      addToCartCustomerFunction(obj);
    }
  }, [
    navigate,
    customer_id,
    userInfo?.trip_id,
    userInfo?.city_id,
    userInfo?.trip_name,
    userInfo?.trip_date,
    userInfo.participant_count,
    userInfo,
    dispatch,
  ]);

  useEffect(() => {
    const updatedInfo = {
      selectedAddOn: [],
      participant_count: 1,
    };

    dispatch(updateUserInformation({ ...userInfo, ...updatedInfo }))
      .then(() => {
        // console.log("userInfo?.selectedAddOn", userInfo?.selectedAddOn);
      })
      .catch((error) => {
        alert("Failed to clear add on.");
      });
  }, []);

  const updateAddOn = (data) => {
    if (userInfo?.selectedAddOn?.length > 0) {
      // Check if the data already exists in selectedAddOn
      const existingIndex = userInfo.selectedAddOn.findIndex(
        (item) => item.title === data.title
      );

      if (existingIndex !== -1) {
        if (data.total === 0) {
          // Remove the item from the array if total is 0
          const updatedSelectedAddOn = userInfo.selectedAddOn.filter(
            (item, index) => index !== existingIndex
          );

          const updatedInfo = {
            selectedAddOn: updatedSelectedAddOn,
          };

          dispatch(updateUserInformation({ ...userInfo, ...updatedInfo }))
            .then(() => {
              // console.log("userinfo", userInfo?.selectedAddOn);
            })
            .catch((error) => {
              alert("Failed to update user information 1.");
              // Handle error scenarios if needed
            });
        } else {
          // Update the total of the existing item
          const updatedSelectedAddOn = [...userInfo.selectedAddOn];
          updatedSelectedAddOn[existingIndex].total = data.total;

          const updatedInfo = {
            selectedAddOn: updatedSelectedAddOn,
          };

          dispatch(updateUserInformation({ ...userInfo, ...updatedInfo }))
            .then(() => {
              // console.log("userinfo", userInfo?.selectedAddOn);
            })
            .catch((error) => {
              alert("Failed to update user information 2.");
              // Handle error scenarios if needed
            });
        }
      } else {
        // Data doesn't exist, add the new data
        const updatedInfo = {
          selectedAddOn: [...userInfo.selectedAddOn, { ...data }],
        };

        dispatch(updateUserInformation({ ...userInfo, ...updatedInfo }))
          .then(() => {
            // console.log("userinfo", userInfo?.selectedAddOn);
          })
          .catch((error) => {
            alert("Failed to update user information 3.");
            // Handle error scenarios if needed
          });
      }
    } else {
      // No data, add the new data
      const updatedInfo = {
        selectedAddOn: [{ ...data, quantity: 1 }],
      };

      dispatch(updateUserInformation({ ...userInfo, ...updatedInfo }))
        .then(() => {
          // console.log("userinfo", userInfo?.selectedAddOn);
        })
        .catch((error) => {
          alert("Failed to update user information 4.");
          // Handle error scenarios if needed
        });
    }
  };
  const avlCapacity = Number(totalCapacity) - Number(totalParticipants);

  const handleUpdateUserInformation = () => {
    // if(avl <= 0)
    let total_fees2 =
      userInfo?.total_fees +
      (addOnTotal ? addOnTotal : 0) -
      (promocodeAmount ? promocodeAmount : 0);
    let gstAmount2 = userInfo?.gstAmount + (addOnTotal * 5) / 100;
    const updatedInfo1 = {
      promocodeAmount: promocodeAmount ? promocodeAmount : 0,
      promocodeUsed: promocodeUsed ? promocodeUsed : "",
      total_fees: total_fees2,
      gstAmount: gstAmount2,
    };
    // console.log("abc total_feesnew", userInfo?.total_fees);
    // console.log("abc addOnTotal", addOnTotal);
    // console.log("abc total_fees2", total_fees2);
    // console.log("abc gstAmount", gstAmount2);

    dispatch(updateUserInformation({ ...userInfo, ...updatedInfo1 }))
      .then(() => {
        navigate("/CustomerRegistration");
      })
      .catch((error) => {
        console.log("error123123", error);
        alert("Failed to update user information 5.");
        // Handle error scenarios if needed
      });
  };
  const updateParticipantCount = (count) => {
    let total_participant_amount = userInfo?.trip_amount * count;
    let gstAmount = (total_participant_amount * 5) / 100;
    let tcsAmount = userInfo?.tcs
      ? (total_participant_amount * userInfo?.tcs) / 100
      : 0;
    let subTotalAmount = total_participant_amount - gstAmount - tcsAmount;
    let total_fees2 = subTotalAmount + gstAmount + tcsAmount;
    // console.log("total_participant_amount", total_participant_amount);
    // console.log("gstAmount", gstAmount);
    // console.log("tcsAmount", tcsAmount);
    // console.log("subTotalAmount", subTotalAmount);
    // console.log("total_fees2", total_fees2);
    const updatedInfo = {
      participant_count: count,
      subTotalAmount: subTotalAmount,
      gstAmount: gstAmount,
      tcsAmount: tcsAmount,
      total_fees: total_fees2,
    };

    dispatch(updateUserInformation({ ...userInfo, ...updatedInfo }))
      .then(() => {
        // console.log("userinfo", userInfo?.selectedAddOn);
      })
      .catch((error) => {
        console.log("error123123", error);
        alert("Failed to update user information 5.");
        // Handle error scenarios if needed
      });
  };
  const getData = useCallback(async () => {
    setLoading(true);
    const payload = {
      tripId: userInfo?.trip_id,
      cityTitle: userInfo?.trip_city,
      date: userInfo?.trip_date,
    };
    const apiData = await getPriceInfo(payload);
    if (apiData) {
      setTotalParticipants(apiData?.data?.totalData?.totalParticipants);
      setTotalCapacity(apiData?.data?.totalData?.prices[0]?.capacity);
    }
    console.log("apiData", apiData);
    setLoading(false);
  }, []);
  useEffect(() => {
    getData();
  }, []);
  const handleDecrement = () => {
    if (participant > 1) {
      updateParticipantCount(participant - 1);
    }
  };

  const handleIncrement = () => {
    if (participant < 100) {
      updateParticipantCount(participant + 1);
    }
  };
  const handleCheckboxChange = (event) => {
    // console.log("event", event.target.checked);
    setIsChecked(event.target.checked);
    // if(event.target.checked === true)
    // {
    //   setIsChecked(false);
    // }
    // else
    // {
    //   setIsChecked(true);

    // }
  };

  const checkCoupon = async () => {
    setPromocodeError("");
    setPromocodeAmount(0);
    setPromocodeUsed("");

    if (coupon) {
      let payload = {
        name: coupon,
        total_amount: userInfo?.total_fees + addOnTotal,
        categoryId: userInfo?.tripCategories,
      };
      // console.log("payload", payload);
      try {
        const apiData = await checkCoupons(payload);
        if (apiData.data?.failedMessage) {
          setPromocodeError(apiData.data?.failedMessage);
        } else {
          // console.log("getData", apiData.data);
          if (apiData?.data?.status === "success") {
            setPromocodeAmount(
              userInfo?.total_fees + addOnTotal - apiData?.data?.newAmount
            );
            setPromocodeUsed(coupon);
          }
        }
      } catch (err) {
        setPromocodeError(err?.response?.data?.message);
      }
    } else {
      setPromocodeError("Please Enter Coupon Name");
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <ToastContainer />
      <div
        style={{
          width: "100%",
          height: "168.8px",
          left: "0px",
          top: "0px",
          background: "#36b54a",
        }}
      >
        <h2 className="loginScreenText">Payment Details</h2>
      </div>

      <div
        className="phoneNumberDivLoginRegistration padding12"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          backgroundColor: "red",
        }}
      >
        <p className="selectParticipantTitle">{userInfo?.trip_name}</p>
        <p
          className="selectParticipantTitle"
          style={{
            color: "#717171",
            fontSize: "1rem",
            lineHeight: "1.8",
            fontWeight: "500",
          }}
        >
          {userInfo?.trip_city}
          <br />
          {userInfo?.trip_date}
        </p>
      </div>

      <div className="padding12 paddingRight50">
        {/* <p className="participantformlabel" style={{ marginTop: "20px" }}>
          avlCapacity : {avlCapacity}
        </p>
        <p className="participantformlabel" style={{ marginTop: "20px" }}>
          totalCapacity : {totalCapacity}
        </p>
        <p className="participantformlabel" style={{ marginTop: "20px" }}>
          totalParticipants : {totalParticipants}
        </p>
        <p className="participantformlabel" style={{ marginTop: "20px" }}>
          Participants : {participant}
        </p> */}

        <div className="quantity-input">
          <button
            className="decrement-btn button_hover"
            onClick={handleDecrement}
          >
            -
          </button>
          <input
            type="number"
            className="qty-input"
            value={participant}
            onChange={(e) => {
              // setParticipant(e.target.value);
            }}
            readOnly
            min="1"
            max="20"
          />
          <button
            className="increment-btn button_hover"
            onClick={handleIncrement}
          >
            +
          </button>
        </div>

        <div style={{ marginTop: "10px" }}>
          <FormControlLabel
            value="end"
            control={
              <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
            }
            labelPlacement="end"
          />
          <span
            className="basicPartiCipant"
            style={{ fontWeight: "600", marginLeft: "-15px" }}
          >
            Got a promo code?
          </span>
        </div>

        {isChecked && (
          <div className="form-group">
            <label htmlFor="" className="loginScreenTitle">
              {" "}
              Promocode
            </label>
            <input
              style={{
                paddingLeft: "10px",
              }}
              onChange={(e) => setCoupon(e.target.value)}
              type="text"
              value={coupon}
              className="phoneNumberInput"
              placeholder="Enter Coupon Code "
            />
            {promocodeError?.length > 0 && (
              <>
                <br />
                <div className="alert alert-danger mt-4">{promocodeError}</div>
              </>
            )}

            <br />
            <Button
              sx={{
                marginTop: "16px",
                width: "136.39px",
                height: "38px",
                background: "#36b54a",
                border: "1px solid #36b54a",
                color: "#FFFFFF",
                // marginBottom:"5px",
                padding: "7px 16.39px 7px 16px",

                borderRadius: "9.6px",
                fontFamily: "Montserrat",
                fontWeight: "700",

                // marginBottom:"10px"
                // marginBottom:"-20px"
              }}
              className="button_hover"
              onClick={(e) => {
                checkCoupon();
              }}
            >
              Verify
            </Button>
          </div>
        )}

        {userInfo?.addOn?.length > 0 && (
          <p className="participantformlabel" style={{ marginTop: "20px" }}>
            Add On Details
          </p>
        )}

        <table className="table" style={{ marginTop: "20px" }}>
          <tbody>
            {userInfo?.addOn &&
              userInfo?.addOn.map((data, i) => {
                return (
                  <tr key={i}>
                    <td className="basicPartiCipant_new">
                      {data.title} <br />
                      <small>{data?.description}</small>
                    </td>
                    <td className="basicPartiCipant_new">
                      ₹ {data.price} <small>(Tax Included)</small>
                    </td>
                    <td className="basicPartiCipant_new">
                      <select
                        name=""
                        style={{ width: "70px" }}
                        className="form-control"
                        id=""
                        onChange={(e) => {
                          const selectedValue = e.target.value; // Convert value to an integer
                          let updatedDetails = {
                            title: data.title,
                            price: data.price,
                            total: data.price * selectedValue,
                          };
                          updateAddOn(updatedDetails);
                        }}
                      >
                        {/* {foe} */}
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <p className="participantformlabel" style={{ marginTop: "20px" }}>
          Payment Details
        </p>

        <table className="table" style={{ marginTop: "20px" }}>
          <tbody>
            <tr>
              <td className="basicPartiCipant_new">Trip Fees</td>
              <td className="basicPartiCipant_new">
                ₹ {trip_amount} x {participant}
              </td>
            </tr>
            <tr>
              <td className="basicPartiCipant_new">Add On</td>
              <td className="basicPartiCipant_new">₹ {addOnTotal} /-</td>
            </tr>
            <tr>
              <td className="basicPartiCipant_new">Subtotal</td>
              <td className="basicPartiCipant_new">
                ₹ {userInfo?.subTotalAmount} /-
              </td>
            </tr>
            <tr>
              <td className="basicPartiCipant_new">GST ({gstValue} %)</td>
              <td className="basicPartiCipant_new">
                ₹ {userInfo?.gstAmount + (addOnTotal * 5) / 100} /-
              </td>
            </tr>
            <tr>
              <td className="basicPartiCipant_new">
                TCS ({userInfo?.trip_tcs} %)
              </td>
              <td className="basicPartiCipant_new">
                ₹ {parseFloat((userInfo?.tcsAmount).toFixed(2))} /-
              </td>
            </tr>
            {promocodeUsed?.length > 0 && (
              <tr>
                <td className="basicPartiCipant_new">
                  Promocode ({promocodeUsed})
                </td>
                <td className="basicPartiCipant_new">
                  - ₹ {promocodeAmount} /-
                </td>
              </tr>
            )}

            <tr>
              <td className="basicPartiCipant">Total Payment</td>
              <td className="basicPartiCipant">
                ₹{" "}
                {parseFloat(
                  (userInfo?.total_fees + addOnTotal - promocodeAmount).toFixed(
                    2
                  )
                )}{" "}
                /-
              </td>
            </tr>
          </tbody>
        </table>

        {Number(participant) <= Number(avlCapacity) ? (
          <Button
            sx={{
              marginTop: "26px",
              width: "100%",
              height: "48px",
              background: "#36b54a",
              border: "1px solid #36b54a",
              color: "#FFFFFF",
              padding: "7px 16.39px 7px 16px",
              borderRadius: "20px",
              fontFamily: "Montserrat",
              fontWeight: "700",
            }}
            className="button_hover"
            onClick={handleUpdateUserInformation}
          >
            Add Details
          </Button>
        ) : (
          <div className="alert alert-danger t_n_c_error mt-2">
            Whoa there! 🚀 It looks like you're trying to book more spots than
            we have available. We can only accommodate {avlCapacity}
            participants per trip. Please adjust your number of participants to
            continue.
          </div>
        )}

        <br />
        <br />
      </div>
    </div>
  );
};
