import React, { useCallback, useEffect, useState } from "react";
import { getTeam } from "../api";
export const Teams = () => {
  const [error, setError] = useState("");
  const [mainData, setMainData] = useState([]);

  const getData = useCallback(async () => {
    try {
      const apiData = await getTeam();
      if (apiData.error) {
        setError(apiData.error);
      } else {
        console.log("getTeam", apiData.data);
        setMainData(apiData.data);
      }
    } catch (err) {
      console.log("err", err.name);
    }
    // setLoading(false);
  }, []);
  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <div style={{ position: "relative", marginBottom: "50px" }}>
      <div
        style={{
          width: "100%",
          height: "200.8px",
          left: "0px",
          top: "0px",
          background: "#36b54a",
          // paddingLeft:"12%",
          // paddingRight:"12%",
        }}
      >
        <h2 className="loginScreenText" style={{ top: "15%" }}>
          The Team
        </h2>
        <h5 className="loginScreenTextBelow">
          Meet the heroes behind TrekPanda
        </h5>
      </div>
      <div className="phoneNumberDivLogin">
        <div className="row">
          {mainData &&
            mainData.map((data, i) => {
              return (
                <>
                  <div className="col-lg-2">
                    <div className="teamsDetails">
                      <img src={data.icon} className="teamImage" alt="" />
                      <br />
                      <span className="teamsDetailsName">{data.name}</span>
                      <br />
                      <span className="teamsDetailsNameDesignation">
                        {data.designation}
                      </span>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};
